import React, {useEffect, useState} from "react";
import c from "./FAQ.module.css"
import {compose} from "redux";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {createMarkup} from "../common/functions";

const FAQ = (props) => {

    const {t} = useTranslation();
    return <div className={c.main_container}>
        <div className={c.main_body}>
            <h1>{t('faq.title')}</h1>
            <div dangerouslySetInnerHTML={createMarkup(t('faq.info'))} className={c.payment_details_text}></div>
        </div>

    </div>
}

const mstp = (state) => {
    return {
        // cats: state.index.catList,
        // item: state.item.item,
        // cart: state.cart.cart,
        // order: state.order,
        // cartItemsFull: state.cart.cartItemsFull
    }

}

export default compose(connect(mstp,{}))(FAQ);