import {createSelector} from "reselect";

export const getSettingsSel = (state) => {
    return state.settings;
}
// export const getItemsListSel = (state) => {
//     return state.admin.settings;
// }
//
// export const getUsersSel = (state) => {
//     return getUsersPrimitive(state).filter(u => true);
// }
//
// export const getIsFetching = (state) => {
//     return state.users.isFetching;
// }
//
// export const getUsersSuper = createSelector(getUsersPrimitive,getIsFetching,(users,f) => {
//     return users.filter(u => true);
// })
