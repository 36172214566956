import React from "react";
import c from "./Footer.module.css"

const Footer = () => {

    return (
        <div className={c.footer}>
             2023
        </div>
    )
}

export default Footer;