import {API} from "../api/api";
import {setNoInet} from "./app_reducer";
import {setCart} from "./cart_reducer";
// import {setItemList} from "./admin_reducer";
// import {setCatList} from "./admin_reducer";

let initialState = {
    order: null,
    orderMessage: null,
    payingOrder: null,
    viewOrder: null,
    deliveryCountry: 'eu'
    // cartOpened: false,
    // cartItemsFull: null
}

const SET_ORDER = 'sweetkids/order/SET_ORDER';
const SET_PAYING_ORDER = 'sweetkids/order/SET_PAYING_ORDER';
const SET_ORDER_MESSAGE = 'sweetkids/order/SET_ORDER_MESSAGE';
const SET_VIEW_ORDER = 'sweetkids/order/SET_VIEW_ORDER';
const SET_DELIVERY_COUNTRY = 'sweetkids/order/SET_DELIVERY_COUNTRY';
// const SET_CART_ITEMS_FULL = 'sweetkids/order/SET_CART_ITEMS_FULL';
// const SET_CART_OPENED = 'sweetkids/order/SET_CART_OPENED';
// const SET_ITEM_LIST = 'sweetkids/index/SET_ITEM_LIST';

const order_reducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_ORDER:
            // debugger
            return {
                ...state,
                order: action.order,
            }
        case SET_PAYING_ORDER:
            // debugger
            return {
                ...state,
                payingOrder: action.payingOrder,
            }
        case SET_ORDER_MESSAGE:
            // debugger
            return {
                ...state,
                orderMessage: action.orderMessage,
            }
        case SET_VIEW_ORDER:
            // debugger
            return {
                ...state,
                viewOrder: action.viewOrder,
            }
        case SET_DELIVERY_COUNTRY:
            // debugger
            return {
                ...state,
                deliveryCountry: action.deliveryCountry,
            }


        default:
            return state;

    }

}

export default order_reducer;



export const setOrder = (order) => {
    return {type: SET_ORDER, order: order};
}
export const setPayingOrder = (payingOrder) => {
    return {type: SET_PAYING_ORDER, payingOrder: payingOrder};
}
export const setOrderMessage = (orderMessage) => {
    return {type: SET_ORDER_MESSAGE, orderMessage: orderMessage};
}
export const setViewOrder = (viewOrder) => {
    return {type: SET_VIEW_ORDER, viewOrder: viewOrder};
}
export const setDelivCountry = (deliveryCountry) => {
    return {type: SET_DELIVERY_COUNTRY, deliveryCountry: deliveryCountry};
}
// export const setCartItemsFull = (cartItemsFull) => {
//     return {type: SET_CART_ITEMS_FULL, cartItemsFull: cartItemsFull};
// }
// export const setCartOpened = (cartOpened) => {
//     return {type: SET_CART_OPENED, cartOpened: cartOpened};
// }

// export const setItemListMain = (items) => {
//     return {type: SET_ITEM_LIST, items: items};
// }


export const getOrder = (orderId = null) => async (dispatch,getState) => {
    try{
        let state = getState();
        let payingOrder_id = 0;
        if(state.order.payingOrder !== null){
            payingOrder_id = state.order.payingOrder.id;
        }

        const result = await API.getOrder(orderId);
        let out = false;
        // console.log('getOrder',orderId);
        // console.log(result.data);

        // todo try...catch...

        if(result.data.error === 0){
            if(result.data.main_data.message === 'no order' || result.data.main_data.message === 'no such order' || result.data.main_data.message === 'could not get order'){

                if(orderId === null){
                    dispatch(setPayingOrder(null))
                }else{
                    dispatch(setOrder(null));
                }
                out = result.data.main_data.message;
            }else{
                if(result.data.main_data.order.status === 'paying'){
                    dispatch(setPayingOrder(result.data.main_data.order))
                }
                if(orderId !== null){
                    dispatch(setOrder(result.data.main_data.order));
                    if(result.data.main_data.order.status !== 'paying' && result.data.main_data.order.id === payingOrder_id){
                        dispatch(setPayingOrder(null))
                    }
                }

                out = 'ok'
                // dispatch(getCartItems());
                // console.log(result2)
                // dispatch(setCartItemsFull(result2.data.main_data));
            }
        }else{
            // error
            out = result.data.error
        }
        dispatch(setOrderMessage(out));
    }
    catch(error){
        // console.log(error)
        dispatch(setNoInet(true))
        // console.log('NoInet')
    }

}
export const getViewOrder = (orderId) => async (dispatch,getState) => {
    try{


        const result = await API.getOrder(orderId);
        let out = false;
        // console.log('getOrder',orderId);
        // console.log(result.data);

        // todo try...catch...

        if(result.data.error === 0){
            if(result.data.main_data.message === 'no order' || result.data.main_data.message === 'no such order' || result.data.main_data.message === 'could not get order'){


                    dispatch(setViewOrder(null));

                out = result.data.main_data.message;
            }else{
                // if(result.data.main_data.order.status === 'paying'){
                //     dispatch(setPayingOrder(result.data.main_data.order))
                // }
                if(orderId !== null){
                    dispatch(setViewOrder(result.data.main_data.order));
                    // if(result.data.main_data.order.status !== 'paying' && result.data.main_data.order.id === payingOrder_id){
                    //     dispatch(setPayingOrder(null))
                    // }
                }

                out = 'ok'
                // dispatch(getCartItems());
                // console.log(result2)
                // dispatch(setCartItemsFull(result2.data.main_data));
            }
        }else{
            // error
            out = result.data.error
        }
        // dispatch(setOrderMessage(out));
    }
    catch(error){
        // console.log(error)
        dispatch(setNoInet(true))
        // console.log('NoInet')
    }

}
// export const getCartItems = () => async (dispatch) => {
//     const result = await API.getCartItems();
//     console.log('getCartItems');
//     console.log(result.data);
//
//     dispatch(setCartItemsFull(result.data.main_data.items));
//     // if(result.data.error === 0){
//     //     if(result.data.main_data.message === 'no cart' || result.data.main_data.message === 'could not get cart'){
//     //         dispatch(setCart(null));
//     //     }else{
//     //         dispatch(setCart(result.data.main_data));
//     //     }
//     // }else{
//     //     // error
//     // }
//
// }

// export const updOrder = (newCartData) => async (dispatch) => {
//     dispatch(setOrder(newCartData));
//     const result = await API.updateOrder(newCartData);
//     // const result2 = await API.getCart();
//     // console.log('getCatListMain');
//     // console.log(result);
// }
export const addOrder = (newOrderData) => async (dispatch) => {
    dispatch(setOrder(newOrderData));
    const result = await API.addOrder(newOrderData);
    dispatch(setCart('empty'));
    // const result2 = await API.getCart();
    // console.log('getCatListMain');
    // console.log();
    return result;
}
export const orderTimeIsUp = (id = 0) => async (dispatch,getState) => {
    // dispatch(setOrder());
    let state = getState();
    let payingOrder_id = 0;
    if(state.order.payingOrder !== null){
        payingOrder_id = state.order.payingOrder.id;
    }

    const result = await API.updateOrderTimeIsUp(id);
    // dispatch(getOrder(id))
    // const result2 = await API.getCart();
    console.log('orderTimeIsUp');
    console.log(result);
    if(result.main_data.message === 'ok - message sent' || result.main_data.message === 'not ok - message NOT sent' ){
        dispatch(setOrder(result.main_data.order));

        if(result.main_data.order.status !== 'paying' && result.main_data.order.id === payingOrder_id){
            dispatch(setPayingOrder(null))
        }

    }

    // return result;
}
export const cancelOrder = (id = 0) => async (dispatch,getState) => {
    // dispatch(setOrder());
    let state = getState();
    let payingOrder_id = 0;
    if(state.order.payingOrder !== null){
        payingOrder_id = state.order.payingOrder.id;
    }

    const result = await API.cancelOrder(id);
    // dispatch(getOrder(id))
    // const result2 = await API.getCart();
    // console.log('orderTimeIsUp');
    // console.log(result);
    if(result.main_data.message === 'ok' ){
        dispatch(setOrder(result.main_data.order));

        if(result.main_data.order.status !== 'paying' && result.main_data.order.id === payingOrder_id){
            dispatch(setPayingOrder(null))
        }

    }

    // return result;
}
export const confirmPayment = (id = 0) => async (dispatch,getState) => {
    // dispatch(setOrder());
    let state = getState();
    let payingOrder_id = 0;
    if(state.order.payingOrder !== null){
        payingOrder_id = state.order.payingOrder.id;
    }

    const result = await API.confirmPayment(id);
    // dispatch(getOrder(id))
    // const result2 = await API.getCart();
    // console.log('orderTimeIsUp');
    // console.log(result);
    if(result.main_data.message === 'ok' ){
        dispatch(setOrder(result.main_data.order));

        if(result.main_data.order.status !== 'paying' && result.main_data.order.id === payingOrder_id){
            dispatch(setPayingOrder(null))
        }

    }

    // return result;
}


// export const getItemListMain = (itemsPerPage,currentPage,cats,sorting) => async (dispatch) => {
//     const result = await API.getFilteredItems(itemsPerPage,currentPage,cats,sorting);
//     // console.log('getItemListMain');
//     // console.log(result);
//     dispatch(setItemListMain(result.items));
// }

// export const getItems = () => async (dispatch)  => {
//     const result = await API.getFilteredItems()
//     console.log('getItems');
//     console.log(result);
// }