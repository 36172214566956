import React, {useState} from "react";
import { reduxForm} from "redux-form";
import {connect} from "react-redux";
import {login, checkAuthorization, setCaptchaAnswer, saveMyInfo} from "../../redux/auth_reducer";
import {NavLink, Redirect} from "react-router-dom";
import {compose} from "redux";
import {createMyField, Hidden, } from "../common/FormsControls/FormsControls";
import {maxLenCreator, minLenCreator, required} from "../../utils/validate/validator";
import c from "./PersonalInfo.module.css"
import {Button, DatePicker, Form, Checkbox, Row, Col} from "antd";
// import "antd/dist/antd.css";
import { Input, Tooltip } from 'antd';
import {InfoCircleOutlined, KeyOutlined, UnlockOutlined, UserOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";



// class LoginContainer extends React.Component { // зробив для тесту, можна і без цього класу
//
//     componentDidUpdate(prevProps, prevState, snapshot) {
//         let p = this.props;
//         let s = this.state;
//         //debugger
//         // todo не працює при лог-ауті на сайті
//     }
//
//     render() {
//         return <Login {...this.props}/>
//     }
// }

const PersonalInfo = (props) => {

    // signup_completed
    // console.log('props')
    // console.log(props)
    // let {tempCaptcha,setTempCaptcha} = useState('');


    const { t } = useTranslation();

    // const [rememberMe,setRM] = useState(true);

    const onSubmit = ({email,pass}) => { //formData
        //
        // if (props.auth.isAuth === false || props.auth.temp === '1') {
        //     props.login(email, pass, rememberMe)
        // } else {
        //     return <Redirect to={'/index'}/>
        // }
        // console.log('rememberMe')
        // console.log(rememberMe)
    }
    // const onSubmit2 = ({email2,pass2,rememberMe2}) => { //formData
    //     //
    //     // console.log('email = ' + email2)
    //     if (props.auth.isAuth === false) {
    //         props.login(email2, pass2, rememberMe2)
    //     } else {
    //         return <Redirect to={'/index'}/>
    //     }
    //     // console.log(formData)
    // }
    return <div className={c.form_container}>
        <h1>{t('info.title')}</h1>
        {/*{(props.match.params.sc && props.match.params.sc === 'signup_completed')*/}
        {/*    ? <div className={c.sc}>{t('auth.signup_completed')} <br/><br/></div>*/}
        {/*    : ''*/}
        {/*}*/}
        <MyInfoReduxForm onSubmit={onSubmit} {...props}  /><br/>
        {/*/!*<LoginReduxForm2 onSubmit={onSubmit2} {...props} />*!/*/}
        {/*{t('auth.if_no_login')} <NavLink to='/signup'>{t('auth.register')}</NavLink>*/}
    </div>
}
const maxLen50 = maxLenCreator(50);
const minLen2 = minLenCreator(2);
// const numOnly = onlyNumbers();

// main login_form
const MyInfoForm = (props) => {

    // remember captcha in State for both accounts (to they both could use it while authorizing)
    // const formChanged = (e) => {
    //     if(e.target.name === 'captcha'){
    //         setCaptchaAnswer(e.target.value)
    //     }
    // }

    const { t } = useTranslation();
    const [loadingButton,setLB] = useState(false)

    const rmClick = (e) => {
        // console.log(e.target.checked);
        // setRM(e.target.checked);
    }

    const onFinish = async (values) => {
        // let rm = values['rememberMe'] === undefined ? false : values['rememberMe'];
        // console.log('Success:', values);
        if (props.auth.isAuth !== false && props.auth.temp !== '1') {
            let answer = props.saveMyInfo(
                values['address'],
                values['city'],
                values['zip'],
                values['np_dep'],
                values['receiver'],
                values['phone'],
                values['pass'])
                .then(()=>{
                    setLB(false)
                })
            // console.log(answer)
        } else {
            return <Redirect to={'/index'}/>
        }
    };

    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo);
        setLB(false)
    };


    const buttonClicked = () => {
        setLB(true)
    }
    const style = { background: '#0092ff', padding: '8px 0' };

    if (props.auth.isAuth !== false && props.auth.temp !== '1' ) {
        return <Row >
            <Col className="gutter-row"
                 xs={{ span: 22, offset: 1 }}
                 sm={{ span: 20, offset: 2 }}
                 md={{ span: 16, offset: 4 }}
                 lg={{ span: 12, offset: 6 }}
                 xl={{ span: 8, offset: 8 }}
                 xxl={{ span: 6, offset: 9 }}
            >

                <Form onSubmit={props.handleSubmit}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}

                      initialValues={{
                          login: props.auth.login,
                          email: props.auth.email,
                          address: props.auth.address,
                          city: props.auth.city,
                          zip: props.auth.zip,
                          np_dep: props.auth.np_dep,
                          receiver: props.auth.receiver,
                          phone: props.auth.phone,
                          pass: props.auth.pass,
                      }}
                >

                    {props.error ? <Form.Item>
                        <div className={(!props.error ? c.noError : '') + ' ' + c.error}>
                            {props.error}
                        </div>
                    </Form.Item> : ''}

                    <Form.Item
                        label={t('info.login_place')}
                        name="login"
                        rules={[
                            {
                                // required: true,
                                message: t('info.login_mess'),
                            },
                        ]}
                    >
                        <Input placeholder={t('info.login_place')}
                            disabled
                            suffix={
                               <Tooltip title={t('info.login_info')}>
                                   <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                               </Tooltip>
                            }
                        />
                        {/*{createMyField('login/email', Input, 'email', [required, maxLen50, minLen2],{
                prefix:<UserOutlined className="site-form-item-icon" />,suffix:
                    <Tooltip title="Extra information">
                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
            })}*/}
                    </Form.Item>

                    <Form.Item
                        label={t('info.email_place')}
                        name="email"
                        rules={[
                            {
                                // required: true,
                                message: t('info.email_mess'),
                            },
                        ]} 
                    >
                        <Input placeholder={t('info.email_place')} disabled
                               suffix={
                                   <Tooltip title={t('info.email_info')}>
                                       <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                   </Tooltip>
                               }
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('info.city_place')}
                        name="city"
                        rules={[
                            {
                                // required: true,
                                message: t('info.city_mess'),
                            },
                        ]} 
                    >
                        <Input placeholder={t('info.city_mess')} />
                    </Form.Item>

                    <Form.Item
                        label={t('info.address_place')}
                        name="address"
                        rules={[
                            {
                                // required: true,
                                message: t('info.address_mess'),
                            },
                        ]} 
                    >
                        <Input placeholder={t('info.address_place')} />
                    </Form.Item>

                    <Form.Item
                        label={t('info.zip_place')}
                        name="zip"
                        rules={[
                            {
                                // required: true,
                                message: t('info.zip_mess'),
                            },
                        ]} 
                    >
                        <Input placeholder={t('info.zip_place')} />
                    </Form.Item>

                    <Form.Item
                        label={t('info.np_dep_place')}
                        name="np_dep"
                        rules={[
                            {
                                // required: true,
                                message: t('info.np_dep_mess'),
                            },
                        ]} 
                    >
                        <Input placeholder={t('info.np_dep_place')} />
                    </Form.Item>

                    <Form.Item
                        label={t('info.receiver_place')}
                        name="receiver"
                        rules={[
                            {
                                // required: true,
                                message: t('info.receiver_mess'),
                            },
                        ]} 
                    >
                        <Input placeholder={t('info.receiver_mess')} />
                    </Form.Item>

                    <Form.Item
                        label={t('info.phone_place')}
                        name="phone"
                        rules={[
                            {
                                // required: true,
                                message: t('info.phone_mess'),
                            },
                        ]} 
                    >
                        <Input placeholder={t('info.phone_place')} />
                    </Form.Item>

                    <Form.Item
                        label={t('info.pass_place')}
                        name="pass"
                        rules={[
                            {
                                required: true,
                                message: t('info.pass_mess'),
                            },
                        ]}
                    >
                        <Input
                            // prefix={<UnlockOutlined/>}
                            placeholder={t('info.pass_place')}
                            suffix={
                                <Tooltip title={t('info.pass_info')}>
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Form.Item>

                    {/*{createMyField('pass', Input.Password, 'pass', [required, maxLen50, minLen2], )}
                    <br/>


                    <div className={c.captchaDiv + ' ' + (!auth.capthaImg ? c.noCaptcha : '')}>
                        <div>
                            <img alt='captcha' src={auth.capthaImg ? auth.capthaImg : ''}/>
                        </div>
                        {createMyField('enter captcha', Input, 'captcha', (!auth.capthaImg ? [] : [required, maxLen50, minLen2]))}
                    </div>

                    {createMyField(null, Input, 'rememberMe', [], {type: 'checkbox',checked: rememberMe,onClick:rmClick}, "Remember me")}
                    <div>*/}

                    {/*<Form.Item name="rememberMe" valuePropName="checked" >
                        <Checkbox>{t('auth.remember_me')}</Checkbox>
                    </Form.Item>*/}

                    {/*<Button  type="primary" onClick={handleSubmit}>Log in</Button>*/}


                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loadingButton} onClick={buttonClicked}>
                            {t('info.save_button')}
                        </Button>
                    </Form.Item>


                    {/*<Button  type="primary" loading>Log in</Button>*/}
                    {/*</div>*/}
                </Form>
            </Col>

        </Row>

    } else {
        return <Redirect to={'/index'}/>
    }

    // return <>test ddd</>

}

// secondary login form for TESTing
// const LoginForm2 = ({auth, handleSubmit, error}) => {
//
//     if (auth.isAuth === false) {
//
//         return <form onSubmit={handleSubmit}>
//
//             <div className={(!error ? c.noError : '') + ' ' + c.error}>
//                 {error}
//             </div>
//             {createMyField(null, Hidden, 'email2', [])}
//             {createMyField(null, Hidden, 'pass2', [])}
//
//             {/*  NO CAPTCHA NEEDED */}
//             {/*<div className={c.captchaDiv + ' ' + (!auth.capthaImg ? c.noCaptcha : '')}>*/}
//             {/*    <div>*/}
//             {/*        <img alt='captcha' src={auth.capthaImg ? auth.capthaImg : ''}/>*/}
//             {/*    </div>*/}
//             {/*    {createMyField('enter captcha', Input, 'captcha2', (!auth.capthaImg ? [] : [required, maxLen50, minLen2]))}*/}
//             {/*</div>*/}
//
//             {createMyField(null, Hidden, 'rememberMe2', [])}
//             <div>
//                 <button>Log in for test</button>
//             </div>
//         </form>
//     } else {
//         return <Redirect to={'/index'}/>
//     }
//
//
// }

const MyInfoReduxForm = reduxForm({
    form: 'myInfo'
})(MyInfoForm)
// const LoginReduxForm2 = reduxForm({
//     form: 'auth2'
// })(LoginForm2)


const mapStateToProps = ({auth}) => {
    //debugger
    return {
        auth: auth,

        initialValues: {
            // login: auth.login,
            // pass: '3AtGqkmDkKfmX6J',
            // email2: 'free@samuraijs.com',
            // pass2: 'free',

        },

        //capthaImg: props.capthaImg
    }
};

export default compose(
    connect(mapStateToProps, {login, checkAuthorization,setCaptchaAnswer,saveMyInfo}),
)(PersonalInfo)

//
// export default () => {
//     return <>
//         <a href={`https://social-network.samuraijs.com/login`} target={`_blank`}>Потрібна авторизація</a>
//     </>
// }