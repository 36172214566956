import {API} from "../api/api";
import {setNoInet} from "./app_reducer";
// import {setItemList} from "./admin_reducer";
// import {setCatList} from "./admin_reducer";

// type InitialStateType = {
//     catList: null | [],
//     itemList: null | [],
//     countItems: number,
//     itemsTotal: number,
//     itemsOrder: string,
//     sortBy: null | [],
//     itemsPerPage: number
// }

type CatListType = any
type ItemListType = any
type SortByListType = any

let initialState = {
    catList: null as CatListType,
    itemList: null as ItemListType,
    countItems: 0,
    itemsOrder: 'asc',
    sortBy: null as SortByListType,
    itemsPerPage: 20,
    itemsTotal: 0,
    searchString: '',
    selectedCats: '',
}

export type InitialStateType = typeof initialState

const SET_CAT_LIST = 'sweetkids/index/SET_CAT_LIST';
const SET_ITEM_LIST = 'sweetkids/index/SET_ITEM_LIST';
const SET_COUNT_ITEMS = 'sweetkids/index/SET_COUNT_ITEMS';
const APPEND_TO_ITEMS = 'sweetkids/index/APPEND_TO_ITEMS';
const SET_ITEMS_TOTAL = 'sweetkids/index/SET_ITEMS_TOTAL';
const SET_SEARCH_STRING = 'sweetkids/index/SET_SEARCH_STRING';
const SET_SORT_BY = 'sweetkids/index/SET_SORT_BY';
const SET_SELECTED_CATS = 'sweetkids/index/SET_SELECTED_CATS';

const index_reducer = (state = initialState, action: any): InitialStateType => {

    switch (action.type) {

        case SET_CAT_LIST:
            // debugger
            return {
                ...state,
                catList: action.catList,
            }
        case SET_ITEM_LIST:
            // debugger
            return {
                ...state,
                itemList: action.items,
            }
        case APPEND_TO_ITEMS:
            // debugger
            return {
                ...state,
                itemList: [...state.itemList, ...action.items] ,
            }
        case SET_COUNT_ITEMS:
            // debugger
            return {
                ...state,
                countItems: action.countItems,
            }
        case SET_ITEMS_TOTAL:
            // debugger
            return {
                ...state,
                itemsTotal: action.itemsTotal,
            }
        case SET_SEARCH_STRING:
            // debugger
            return {
                ...state,
                searchString: action.searchString,
            }
        case SET_SORT_BY:
            // debugger
            return {
                ...state,
                sortBy: action.sortBy,
            }
        case SET_SELECTED_CATS:
            // debugger
            return {
                ...state,
                selectedCats: action.selectedCats,
            }

        default:
            return state;

    }

}

export default index_reducer;



export const setCatListMain = (catList: CatListType) => {
    return {type: SET_CAT_LIST, catList: catList};
}
export const setItemListMain = (items:ItemListType) => {
    return {type: SET_ITEM_LIST, items: items};
}
export const setCountItems = (countItems:number) => {
    return {type: SET_COUNT_ITEMS, countItems: countItems};
}
export const appendToItemListMain = (items:ItemListType) => {
    return {type: APPEND_TO_ITEMS, items: items};
}
export const setItemsTotal = (itemsTotal:number) => {
    return {type: SET_ITEMS_TOTAL, itemsTotal: itemsTotal};
}
export const setSearchString = (searchString:string) => {
    return {type: SET_SEARCH_STRING, searchString: searchString};
}
export const setSortBy = (sortBy:Object) => {
    return {type: SET_SORT_BY, sortBy: sortBy};
}
export const setSelectedCats = (selectedCats: string) => {
    return {type: SET_SELECTED_CATS, selectedCats: selectedCats};
}


export const getCatListMain = () => async (dispatch: any) => {
    try{
        const result = await API.getCats();
        if(result !== undefined){
            dispatch(setCatListMain(result.cats));
        }
    }catch(error){
        console.log(error)
        // dispatch(setNoInet(true))
    }
}


export const getItemListMain = (itemsPerPage:number,currentPage:number) => async (dispatch:any,getState:any) => {
    try{
        let state = getState();
        let ss = state.index.searchString;
        let sb = state.index.sortBy;
        let cats = state.index.selectedCats;
        const result = await API.getFilteredItems(itemsPerPage,0,cats,sb,ss);
        if(result !== undefined && result.items !== undefined){
            // console.log('getItemListMain');
            // console.log(result);
            dispatch(setItemListMain(result.items));
            dispatch(setCountItems(result.items.length));
            dispatch(setItemsTotal(result.total));
        }else{
            // dispatch(setNoInet(true))
        }


    }
    catch(error){
        console.log(error)
        // dispatch(setNoInet(true))
    }
}

export const getMoreItems = () => async (dispatch:any,getState:any) => {
    // try{
        let state = getState();
        // console.log(state);
        let ipp = state.index.itemsPerPage
        let io = state.index.itemsOrder
        let ci = state.index.countItems
        let ss = state.index.searchString;
        let sb = state.index.sortBy;
        let cats = state.index.selectedCats;
        const result = await API.getMoreFilteredItems(ipp,ci,cats,sb,ss);
        // console.log(result);
        if(result !== undefined){
            // console.log('getItemListMain');
            console.log(result);
            dispatch(appendToItemListMain(result.items));
            dispatch(setCountItems(ci + result.items.length));
            dispatch(setItemsTotal(result.total));

        }else{
            // dispatch(setNoInet(true))
        }


    // }
    // catch(error){
    //     console.log(error)
    //     // dispatch(setNoInet(true))
    // }
}

// export const getItems = () => async (dispatch)  => {
//     const result = await API.getFilteredItems()
//     console.log('getItems');
//     console.log(result);
// }