import React, {useEffect, useState} from "react";
// import {render} from "react-dom";
// import c from "./Header.module.css"
// import logo from "./../../imgs/logo.png"
// import cart from "./../../imgs/shopping-cart.svg"
// import search from "./../../imgs/search.png"
// import {NavLink} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
// import {logOut} from "../../redux/auth_reducer";
// import {Affix, Badge, Button, Col, Image, Input, InputNumber, Row} from "antd";
// import {useTranslation} from "react-i18next";
// import i18n from "i18next";
// import {getCart, getCartItems, setCartOpened} from "../../redux/cart_reducer";
// import {DeleteOutlined, HeartOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
// import Cart from "../Cart/Cart";
import {
    getDiff
} from "../common/functions";
import {getOrder} from "../../redux/order_reducer";

const Redirector = (props) => {

    // const logoutUser = () => {
    //     console.log('logout')
    //     props.logOut()
    // }


    useEffect(()=>{
        // console.log(props)
        if(props.order.order === null){
            // props.getOrder();
        }

        // if(props.auth.isAuth){
        //
        //     if(props.cart === null){
        //         props.getCart()
        //         // getCartItems()
        //     }
        //     // if(props.cart !== null && props.cartItemsFull === null){
        //     //     // props.getCart()
        //     //     props.getCartItems(parseCartItemsIds(props.cart.items))
        //     // }
        //
        //     if(props.cartItemsFull !== null && props.cartItemsFull !== undefined){
        //         // console.log(props.cartItemsFull)
        //         setCartCount(props.cartItemsFull.length)
        //     }
        // }


    },[])
    // },[props.cartItemsFull,props.cart,props.order.order !== null ? props.order.order : null])




    let redirect = false;

    // console.log(props.order,props.settings)
    if(props.order.order === null || props.order.order === undefined || props.settings.settings === null){
        return null;
    }else{
        let [minutes,seconds] = getDiff(props.order.order.create_date,parseInt(props.settings.settings.pay_wait_time));
        // console.log(props.order.order.create_date,props.settings.settings)
        // console.log(minutes,seconds)
        if(minutes > 0 || (minutes === 0 && seconds > 0) ){
            redirect = true;
        }
        return (
            (props.order.order.status === 'paying' && redirect) ? <Redirect to={'/pay/'+props.order.order.id} /> : null
        )
    }

}

const mstp = ({order,settings}) => {

    return {
        order: order,
        settings:settings
        // cart: state.cart.cart,
        // cartOpened: state.cart.cartOpened,
        // cartItemsFull: state.cart.cartItemsFull
    }
};

export default compose(connect(mstp,{getOrder}))(Redirector);