import React, {useEffect, useState} from "react";
import c from "./AdminSettings.module.css";
import AdminLinks from "../AdminLinks/AdminLinks";
import {getCatListSel, getItemsListSel} from "../../redux/admin_selectors";
import {connect} from "react-redux";
import {delItem, getCatList, getItemList, saveItem} from "../../redux/admin_reducer";
import Preloader from "../Preloader/Preloader";
import {Redirect, withRouter} from "react-router-dom";
import AdminItemList from "../AdminItemList/AdminItemList";
import {compose} from "redux";
import {CheckBox, createMyField, Hidden, Input, Textarea} from "../common/FormsControls/FormsControls";
import {reduxForm} from "redux-form";
import Info from "../common/Info/Info";
import {required} from "../../utils/validate/validator";
import AdminPhotoView from "../AdminPhotoView/AdminPhotoView";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import {getSettingsSel} from "../../redux/user_selectors";
import {getSettings, updateSettings} from "../../redux/settings_reducer";
// import { SortablePane, Pane } from 'react-sortable-pane';


const AdminSettings =  (props) => {
    return <>
        перегляд налаштувань <br/>
        <>налаштувань поки нема</>
        <br/><br/>
        додати налаштування<br/>
        <>
            форма додавання
        </>
    </>
}

const mstp = (state,props) => {


    return {
        settings: getSettingsSel(state),

    }
}

export default compose(withRouter,connect(mstp,{getSettings,updateSettings}))(AdminSettings);