import React from "react";
import c from "./AdminUsersList.module.css";
import AdminLinks from "../AdminLinks/AdminLinks";

const AdminUsersList = (props) => {

    return (
        <div className={c.main_body}>
            AdminUsersList
        </div>
    )
}

export default AdminUsersList;