import React, {memo, useEffect, useState} from "react";
import { reduxForm} from "redux-form";
import {connect} from "react-redux";
import {login, checkAuthorization, setCaptchaAnswer} from "../../redux/auth_reducer";
import {NavLink, Redirect} from "react-router-dom";
import {compose} from "redux";
import {createMyField, Hidden, } from "../common/FormsControls/FormsControls";
import {maxLenCreator, minLenCreator, required} from "../../utils/validate/validator";
import c from "./Pay.module.css"
import {Button, DatePicker, Form, Checkbox, Row, Col, Radio, Image, Tag} from "antd";
import qr_mono from "../../imgs/card_qr_mono.png"
import qr_revolut from "../../imgs/card_qr_revolut.png"
// import "antd/dist/antd.css";
import { Input, Tooltip } from 'antd';
import {
    EnvironmentOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    KeyOutlined,
    NumberOutlined, PhoneOutlined,
    UnlockOutlined,
    UserOutlined
} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import CartPreview from "../CartPreview/CartPreview";
import {getCart} from "../../redux/cart_reducer";
import {cancelOrder, confirmPayment, getOrder, orderTimeIsUp} from "../../redux/order_reducer";
import Preloader from "../Preloader/Preloader";
import {getSettingsSel} from "../../redux/user_selectors";
import {getSettings} from "../../redux/settings_reducer";
import {
    clearAllIntervals, createMarkup, getColorByStatus,
    getDiff, minutes_seconds_text
} from "../common/functions";


const Pay = ((props) => {
    // console.log(props)


    const [startTime,setST] = useState(0);
    const [length,setL] = useState(0);
    const [payment_timer,set_payment_timer] = useState([60,0]);
    const [payment_timer_started,set_payment_timer_started] = useState(false);
    const [get_order_launched,set_get_order_launched] = useState(false);
    // console.log('payment_timer_started')
    // console.log('component loaded',payment_timer_started)
    // console.log(payment_timer_started)
    // let length = 0;
    // const [needTimer,snt] = useState(false);
    // let startTime = '1631821216';
    // let length = 0;
    // console.log(props.settings)

    // const getOrderResp = async () => {
    //     return await props.getOrder()
    // }

    let order_id = 0;
    if(props.match.params.id && props.match.params.id !== ''){
        order_id = parseInt(props.match.params.id)
    }

    // if(props.order.order !== null && props.order.order.id !== order_id){
    //     set_get_order_launched(true)
    //     props.getOrder(order_id)
    // }else{
    //     if(props.order.order === null && !get_order_launched){
    //         set_get_order_launched(true)
    //         props.getOrder(order_id)
    //         console.log('getOrder', order_id)
    //     }else{
    //         // console.log(props.order.order)
    //     }
    // }



    useEffect(()=>{
        if(props.order.order === null){
            // console.log(0)
            props.getOrder(order_id)
        }else{
            // console.log(props.order.order.id)

            if(props.order.order !== null && props.order.order.id !== order_id){
                    // set_get_order_launched(true)
                props.getOrder(order_id)
            }

            // if(props.order.order === null && !get_order_launched){
            //     set_get_order_launched(true)
            //     props.getOrder(order_id)
            //     console.log('getOrder', order_id)
            // }else{
            //     // console.log(props.order.order)
            // }
        }

    },[order_id])
    // useEffect(()=>{
    //     // console.log('useEffect 1')
    //     // console.log(props.order.order,props.order.order !== null ? props.order.order.create_date : null,props.order.orderMessage)
    //
    //
    // },[props.order.order,props.order.orderMessage])

    if(props.order.orderMessage !== null ){
        if(props.order.orderMessage === 'no such order'){
            props.history.push('/')
            // console.log(order_id,props.order.orderMessage)
        }
    }


    useEffect(()=>{
        if(props.order.order !== null && props.order.order !== undefined && props.order.order.create_date > 0 && startTime <= 0){

            // console.log(startTime)
        }
    },[props.order.order !== null ? props.order.order.create_date : null])


    useEffect(()=>{
        // console.log('useEffect 2')

        if(props.settings.settings === null || props.settings.settings === undefined){
            props.getSettings()
        }

        // console.log(props.settings)
        // console.log(startTime)
    },[(props.settings.settings !== null && props.settings.settings !== undefined) ? props.settings.settings.pay_wait_time : null])

    if(props.settings.settings !== null && props.settings.settings.pay_wait_time !== undefined && length <= 0){
        setL(parseInt(props.settings.settings.pay_wait_time));
        // console.log(parseInt(props.settings.settings.pay_wait_time))

    }

    useEffect(()=>{
        set_payment_timer_started(false)
    },[length,startTime])

    // useEffect(()=>{
    //     console.log(parseInt(startTime), parseInt(length))
    //     if(parseInt(startTime) > 0 && parseInt(length) > 0){
    //         let [minutes,seconds] = getDiff(parseInt(startTime),parseInt(props.settings.settings.pay_wait_time));
    //         if(minutes > 0 || (minutes === 0 && seconds > 0) ){
    //             snt(true)
    //             console.log(minutes,seconds)
    //         }
    //     }
    //
    // },[startTime,length])

    const { t } = useTranslation();
    const [main_interval_started,set_main_interval_started] = useState(false);


    const getTimer = (start,len) => {
        // console.log(start,len)
        // if(start === 0 || len === 0){
        //     setTimeout(()=>{
        //         getTimer(parseInt(startTime),parseInt(len))
        //     },1000)
        // }else{
        // if(props.settings.settings !== null){
        //     console.log(props.settings.settings.pay_wait_time)
        //     len = props.settings.settings.pay_wait_time
        // }
        //
        // if(props.order.order !== null){
        //     console.log(props.order.order.create_date)
        //     start = props.order.order.create_date
        // }


            let [minutes,seconds] = getDiff(start,len);
            // console.log(minutes,seconds)

            if(minutes > 0 || (minutes === 0 && seconds > 0) ){
                // getTimer(start,len)
                setTimeout(()=>{
                    set_payment_timer([minutes,seconds])
                    getTimer(parseInt(start),parseInt(len))
                },1000)
            }else{
                // console.log('time is up');
                props.orderTimeIsUp(props.order.order.id);
                set_payment_timer([0,0])
            }
        // }

        // }

    }


    // if(!payment_timer_started && !main_interval_started){
    //     let intervalCounter = 0;
    //     // let interv = window.setInterval(()=>{
    //         set_main_interval_started(true)
    //         if(intervalCounter < 10 && !payment_timer_started){
    //             intervalCounter = intervalCounter + 1;
    //             console.log('interv 0',parseInt(startTime),parseInt(length),!payment_timer_started,props.settings.settings !== null ? props.settings.settings.pay_wait_time : null,props.order.order !== null ? props.order.order.create_date : null)

                if(parseInt(startTime) > 0 && parseInt(length) > 0 && !payment_timer_started){
                    set_payment_timer_started(true)
                    // console.log('set_payment_timer_started')
                }

                let get_create_date = () => {
                    // console.log(props.order.order.create_date)
                    if(props.order.order !== null){
                        return props.order.order.create_date
                    }else{
                        return 0
                    }
                }
                let get_pay_wait_time = () => {
                    // console.log(props.settings.settings.pay_wait_time)
                    if(props.settings.settings !== null){
                        return props.settings.settings.pay_wait_time
                    }else{
                        return 0
                    }
                }

                let test_go = () => {
                    // console.log('test_go')
                    let a = get_create_date()
                    let b = get_pay_wait_time()
                    if(a > 0 && b > 0){
                        // console.log('finish',a,b)
                        // start counter
                        if(parseInt(a) > 0 && parseInt(b) > 0){
                            let [minutes,seconds] = getDiff(parseInt(a),parseInt(b));

                            if(!payment_timer_started){
                                // clearAllIntervals()
                                set_payment_timer_started(true)
                                if(minutes > 0 || (minutes === 0 && seconds > 0) ){
                                    // snt(true)
                                    // console.log('interv 1')
                                    // clearInterval(interv)
                                    getTimer(parseInt(a),parseInt(b))

                                }else{ // stop if time has passed
                                    if(props.order.order !== null && props.order.order !== undefined && props.order.order.status === 'paying'){
                                        // clearInterval(interv)
                                        // console.log('time is up');
                                        props.orderTimeIsUp(props.order.order.id);
                                        set_payment_timer([0,0])
                                    }else{
                                        set_payment_timer([0,0])
                                    }

                                }
                            }
                        }
                    }else{
                        setTimeout(()=>{
                            // console.log('go on',a,b)
                            test_go()
                        },5000)
                    }

                }

                useEffect(()=>{
                    if(props.settings.settings !== null)
                    {
                        // console.log('setL')
                        // console.log(props.settings.settings.pay_wait_time)
                        setL(props.settings.settings.pay_wait_time)
                    }

                    if(props.order.order !== null){
                        // console.log(props.order.order.create_date)
                        setST(props.order.order.create_date)
                    }

                    // if(props.settings.settings !== null && props.order.order !== null){
                    //     set_main_interval_started(true)
                    // }


                },[props.settings.settings !== null ? props.settings.settings.pay_wait_time : null,
                    props.order.order !== null ? props.order.order.create_date : null])

    // if(main_interval_started){

        // setTimeout(()=>{

            // console.log(startTime,length)

            // if(!payment_timer_started && startTime > 0 && length > 0){
            //
            //
            //     let [minutes,seconds] = getDiff(parseInt(startTime),parseInt(length));
            //     // console.log(minutes,seconds)
            //     if(minutes > 0 || (minutes === 0 && seconds > 0) ){
            //         // snt(true)
            //         // console.log('interv 1')
            //         // clearInterval(interv)
            //         getTimer(parseInt(startTime),parseInt(length))
            //
            //         // set_payment_timer_started(true)
            //     }else{
            //         if(props.order.order !== null && props.order.order !== undefined && props.order.order.status === 'paying'){
            //             // clearInterval(interv)
            //             console.log('time is up');
            //             props.orderTimeIsUp();
            //         }
            //
            //     }
            //
            //
            // }


        test_go();

    // }




                //
                // if(parseInt(startTime) <= 0 || parseInt(length) <= 0 || payment_timer_started){
                //
                // }
                //
                // if(!needTimer){
                //
                // }
    //         }else{
    //             // clearInterval(interv)
    //             // clearInterval(interv)
    //         }
    //
    //     // },200) // інакше при завантаженні сторінки оплати таймер не запускається
    //
    // }


    let time_is_up = (
        (payment_timer[0] === 0 && payment_timer[1] === 0)
        ||  (payment_timer[0] === 0 && payment_timer[1] < 0)
        || payment_timer[0] < 0
    ) ? true : false;

    const cancel_order = () => {
        props.cancelOrder(order_id);
    }
    const confirm_payment = () => {
        props.confirmPayment(order_id);
    }

    const getClassByStatus = (status) => {
        let out = '';
        switch (status) {
            case 'paying':
                out = time_is_up ? c.payment_amount_grey : c.payment_amount;
                break;
            case 'canceled':
                out = c.payment_amount_grey;
                break;

            default:

                out = c.payment_amount_grey;
                break;
        }
        return out;
    }

    const getTextByStatus = (status) => {
        let out = '';
        switch (status) {
            case 'paying':
                out = (time_is_up
                    ? <div dangerouslySetInnerHTML={createMarkup(t('pay.time_is_up'))} className={c.time_is_up}></div>
                    : <div className={c.order_paying}>{t('pay.timer_descr')} - <span className={c.payment_timer}>{
                        // getTimer(props.order.order.create_date,props.settings.settings.pay_wait_time)
                        (payment_timer[0] > 0
                                ? payment_timer[0] + t('pay.timer_minutes_' + minutes_seconds_text(payment_timer[0])) + ' '
                                : ''
                        )
                        + (payment_timer[1] > 0
                                ? payment_timer[1] + t('pay.timer_seconds_' + minutes_seconds_text(payment_timer[1]))
                                : ''
                        )
                    }</span></div>);
                break;
            // case 'check_time':
            //     out = <div dangerouslySetInnerHTML={createMarkup(t('pay.time_is_up'))} className={c.time_is_up}></div>;
            //     break;
            // case 'canceled':
            //     out = <div className={c.order_canceled}>Замовлення скасоване</div>;
            //     break;
            // case 'confirmed_by_user':
            //     out = <div className={c.order_confirmed_by_user}>Замовлення підтверджене замовником та чекає схвалення Адміністратором</div>;
            //     break;
            // case 'confirmed':
            //     out = <div className={c.order_confirmed}>Замовлення оплачене та підтверджене Адміністратором</div>;
            //     break;
            // case 'delivering':
            //     out = <div className={c.order_sent}>Замовлення вже в дорозі</div>;
            //     break;
            // case 'completed':
            //     out = <div className={c.order_completed}>Замовлення виконане</div>;
            //     break;

            default:

                out = <Tag color={getColorByStatus(status)} key={status}>
                    <div dangerouslySetInnerHTML={createMarkup(t('order_status.'+status).toUpperCase())}></div>
                </Tag>;
                break;
        }
        return out;
    }

    const getPartPaymentAmount = (total,country) => {
        if(country === 'eu')
            return 7
        else
            return (total * 0.1 + 20)
    }

    return (order_id <= 0
            ? <>{t('order_status.error')}</>
            : ((props.order.order === null || props.order.order === undefined || parseInt(startTime) <= 0 || parseInt(length) <= 0  || props.settings.settings === null || props.settings.settings === undefined)
                ? <><Preloader />{(startTime)} {(length)}</>
                : <div className={c.payment_details_container}>
                {/*{parseInt(startTime)}*/}
                {/*{parseInt(length)}*/}
        <h1 className={c.payment_details_header}>{t('pay.title')}</h1>
        <div className={c.payment_data}>
            {t('pay.order_id')} - <span className={c.order_id}>{order_id}</span><br/>
            {t('pay.payment_amount')} - <span className={
            getClassByStatus(props.order.order.status)
            }>{props.order.order.pay_way === 'part'
            ? getPartPaymentAmount(props.order.order.total_amount, props.order.order.delivery_country)
            : props.order.order.total_amount} {t('common.curr_sign')}</span><br/>
            {
                getTextByStatus(props.order.order.status)
            }


        </div>
        <div className={c.payment_details_qr}>
            <Image src={qr_mono} className={c.qr} width={200}/>
            <Image src={qr_revolut} className={c.qr} width={200}/>
        </div>
        <div dangerouslySetInnerHTML={createMarkup(t('pay.main_text'))} className={c.payment_details_text}></div>
        <br/>

                    <Button onClick={cancel_order}
                            disabled={time_is_up || props.order.order.status !== 'paying'
                        ? 'disabled'
                        : null}
                            className={time_is_up || props.order.order.status !== 'paying' ? c.disabled_btn : c.cancel_order}>{t('pay.cancel_button')}</Button>
                    <Button onClick={confirm_payment}
                            disabled={time_is_up || props.order.order.status !== 'paying'
                        ? 'disabled'
                        : null}
                            className={time_is_up || props.order.order.status !== 'paying' ? c.disabled_btn : c.confirm_payment}>{t('pay.confirm_payment_button')}</Button>

                    {/*{t('auth.if_no_login')} <NavLink to='/signup'>{t('auth.register')}</NavLink>*/}
    </div>)
    )
})


// const mapStateToProps = ({auth,order,settings}) => {
//     //debugger
//     return {
//         auth: [],
//         order: [],
//         settings: []

const mapStateToProps = ({order,settings}) => {
    //debugger
    return {
        // auth: auth,
        order: order,
        settings: settings
        // initialValues: {
        //     email2: 'wovo4ka2010@gmail.com',
        //     pass2: '3AtGqkmDkKfmX6J',
        //     // email2: 'free@samuraijs.com',
        //     // pass2: 'free',
        //     rememberMe2: true,
        //
        // },

        //capthaImg: props.capthaImg
    }
};

export default compose(
    connect(mapStateToProps, {getOrder,orderTimeIsUp,getSettings,cancelOrder,confirmPayment}),
)(Pay)

