

import { createGlobalStyle} from "styled-components"

export const GlobalStyles = createGlobalStyle`

* {

background: ${({ theme }) => theme.body} !important;

color: ${({ theme }) => theme.text} !important;

font-family:  Roboto;

transition: all 0.50s linear;

}`
