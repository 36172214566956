import React, {useEffect, useState} from "react";
import c from "./Favourites.module.css";
import AdminLinks from "../AdminLinks/AdminLinks";
import {getCatListSel, getItemsListSel} from "../../redux/admin_selectors";
import {connect} from "react-redux";
import {delItem, getCatList, getItemList, saveItem} from "../../redux/admin_reducer";
import Preloader from "../Preloader/Preloader";
import {NavLink, Redirect, withRouter} from "react-router-dom";
import AdminItemList from "../AdminItemList/AdminItemList";
import {compose} from "redux";
import {CheckBox, createMyField, Hidden, Input, Textarea} from "../common/FormsControls/FormsControls";
import {reduxForm} from "redux-form";
import Info from "../common/Info/Info";
import {required} from "../../utils/validate/validator";
import AdminPhotoView from "../AdminPhotoView/AdminPhotoView";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import {getFavs, getMyOrders} from "../../redux/user_reducer";
import {Carousel, Image, Table, Tag} from "antd";
import {useTranslation} from "react-i18next";
import {Header} from "antd/lib/layout/layout";
import {createMarkup, getColorByStatus} from "../common/functions";
import FavouritesModule from "../FavouritesModule/FavouritesModule";
import i18n from "i18next";



// https://docs.dndkit.com/presets/sortable





const Favourites = (props) => {

    const { t } = useTranslation();
    useEffect(()=>{
        if(props.favourites === null){
            // console.log('test 1')
            props.getFavs()
        }else{
            // console.log('test 2')
            // console.log(props.favourites)
        }


    },[])





    // if(props.myOrders !== null){
    //     console.log(props.myOrders)
    // }

    // function parseDate(number) {
    //     let d = new Date(+number * 1000)
    //     function pad(s) { return (s < 10) ? '0' + s : s; }
    //     // var d = new Date(inputFormat)
    //     return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/') + ' '
    //         + [pad(d.getHours()), pad(d.getMinutes()), pad(d.getSeconds())].join(':')
    // }

    const getPhotoAndLink = (id,photoString,name) => {
        // let itemArr = '';
        // let photos = [];
        //
        // if(props.favourites !== null){
        //     itemArr = props.favourites.map((i)=> {
        //         console.log(i.id, props.id)
        //         return i.id === id ;
        //     })
        //     console.log(props.favourites)
        //     console.log(itemArr)
        //     // itemArr = props.favourites
        //     photos = itemArr.photo.split(',')
        //     // availableCount = parseInt(props.item.count) - parseInt(props.item.booked);
        // }else{
        //
        // }
        // console.log(photoString)
        let photos = photoString.split(',');
        return <div><span className={c.gallery}>
            {photos.length > 0
                ? <Image key={photos[0]} className={c.img}  src={"/imgs/"+photos[0]} />

                // <Carousel autoplay>
                //     {photos.map((ph)=> {
                //         return <div key={ph}>
                //             <div style={contentStyle}>
                //                 {/*<img src={"https://sweetkids.site/imgs/"+ph} className={c.photo}/>*/}
                //                 <Image
                //                     // width={200}
                //                     src={"https://sweetkids.site/imgs/"+ph}
                //                 />
                //             </div>
                //         </div>
                //     })}
                //
                // </Carousel>

                : <div>no photo</div>}
        </span>
        <NavLink className={c.item_link} to={'/item/'+id}>{name}</NavLink>
        </div>
    }




    let dataSource = Array()
    if(props.favourites !== null && props.favourites !== undefined){
        // let dataSource = Array()
            props.favourites.map((f)=>{
                dataSource.push( {
                    item : getPhotoAndLink(f.id,f.photo,(i18n.language === 'en' ? f.name : f.name_ru)),
                    count : f.count,
                    price : f.price + ' ' + t('common.curr_sign'),
                    id : f.id,
                    key : f.id
                    /*item : f.title,
                update_date : parseDate(o.update_date),
                amount : o.total_amount + ' ' + t('pay.valuta'),
                status : o.status*/
        })

                // return <><NavLink to={'/pay/'+o.id}>{o.id+' '+o.update_date +' '+o.total_amount +' '+o.status}</NavLink><br/></>
            })
        // console.log(dataSource)
    }


    const columns = [
        {
            // title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: id => (
                <>
                    {<FavouritesModule id={id} />}
                </>
            ),
        },
        {
            title: t('favourites.item'),
            dataIndex: 'item',
            key: 'item',
        },
        {
            title: t('favourites.count'),
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: t('favourites.price'),
            dataIndex: 'price',
            key: 'price',
        },
        /*
        {
            title: t('myOrders.status'),
            dataIndex: 'status',
            key: 'status',
            render: status => (
                <>
                    {<Tag color={getColorByStatus(status)} key={status}>
                        {t('order_status.'+status).toUpperCase()}
                    </Tag>

                    }
                </>
            ),
        },
        {
            title: t('myOrders.actions'),
            key: 'actions',
            render: (text, record) => (
                    <NavLink to={'/view_order/'+record.id}>{t('myOrders.view_order')}</NavLink>
            ),
        },*/
    ];



    return (props.favourites === null ? <>No favourites</> : <>

        {props.auth.isAuth && props.auth.login !== 'no_login'
            ? <div className={c.main_container}>
                <div className={c.header}>{t('favourites.title')}</div>
                <>
                    {/*ID DATE AMOUNT STATUS<br/>*/}

                    <Table dataSource={dataSource} columns={columns} pagination={{ pageSize: 20, hideOnSinglePage:true }} />
                </>

            </div>
            : <div dangerouslySetInnerHTML={createMarkup(t('cabinet.not_authorized'))}></div>
        }
    </>)
}

const mstp = (state,props) => {



    return {
        auth: state.auth,
        favourites: state.user.favourites
    }
}

export default compose(withRouter,connect(mstp,{getFavs}))(Favourites);