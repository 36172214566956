let initialState = {
    theme: 'light'
}

const SET_THEME = 'sweetkids/theme/SET_THEME';

const theme_reducer = (state = initialState, action:any) => {
    switch (action.type) {
        case SET_THEME:
            return {
                ...state,
                theme: action.theme,
            }
        default:
            return state;
    }
}
export default theme_reducer;

export const setTheme = (theme:string) => {
    return {type: SET_THEME, theme: theme};
}