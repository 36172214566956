import React from 'react';
import styles from './Error.module.css'

 const Error = ({text,visible}) => {
    return (
        <span className={styles.error + " " + (visible && styles.visible)}>
            {text}
        </span>
    )
}

export default Error;


