import React, {useEffect} from 'react';
import styles from './Checker.module.css'
import { RouteComponentProps } from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {getOrder, orderTimeIsUp} from "../../../redux/order_reducer";
import {getSettings} from "../../../redux/settings_reducer";
import {checkInet, setCheckerStarted} from "../../../redux/app_reducer";



 const Checker = (props) => {

     const timer = () => {
         setTimeout(()=>{
             checkOrder();
             checkInet();
             // console.log('timer')
             timer()
         },10000)
     }

     const checkOrder = () => {
         // let orderId = 0;
         if(props.order.payingOrder !== null){
             props.getOrder(props.order.payingOrder.id)
         }else if(props.order.order !== null){
             props.getOrder(props.order.order.id)
         }else{
             props.getOrder();
         }

     }

     const checkInet = () => {
             props.checkInet();
     }


     useEffect(()=>{
         if(!props.checker_started){
             timer()
             props.setCheckerStarted(true)
         }
     },[])

    return null
}

// export default Checker;
const mapStateToProps = ({order,settings,app}) => {
    //debugger
    return {
        // auth: auth,
        order: order,
        settings: settings,
        checker_started: app.checker_started
    }
}

export default compose(
    connect(mapStateToProps, {getOrder,orderTimeIsUp,getSettings,checkInet,setCheckerStarted}),
)(Checker)

