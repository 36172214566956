import React, {useEffect, useState} from "react";
import { reduxForm} from "redux-form";
import {connect} from "react-redux";
import {
    login,
    checkAuthorization,
    setCaptchaAnswer,
    recover,
    checkRid,
    setPassword,
    setRecoverIdSent, setRecoverError
} from "../../redux/auth_reducer";
import {NavLink, Redirect} from "react-router-dom";
import {compose} from "redux";
import {createMyField, Hidden, } from "../common/FormsControls/FormsControls";
import {maxLenCreator, minLenCreator, required} from "../../utils/validate/validator";
import c from "./Recover.module.css"
import {Button, DatePicker, Form, Checkbox, Row, Col} from "antd";
// import "antd/dist/antd.css";
import { Input, Tooltip } from 'antd';
import {InfoCircleOutlined, KeyOutlined, UnlockOutlined, UserOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";

const RecoverContainer = (props) => {

    // useEffect(() => {
    //     let p = this.props;
    //     let s = this.state;
    // },[])


    return <Recover {...props}/>
}



const Recover = (props) => {

    // signup_completed
    // console.log('props')
    // console.log(props)
    // let {tempCaptcha,setTempCaptcha} = useState('');


    const { t } = useTranslation();

    // const [rememberMe,setRM] = useState(true);

    const onSubmit = ({email}) => { //formData
        //
        if (props.auth.isAuth === false || props.auth.temp === '1') {
            props.recover(email)
        } else {
            return <Redirect to={'/index'}/>
        }
        // console.log('rememberMe')
        // console.log(rememberMe)
    }
    useEffect(()=>{
        if(props.match.params.rid){
            if(props.auth.recoverIdConfirmed === null){
                if(props.auth.recoverIdSent === false){
                    props.checkRid(props.match.params.rid)
                }
            }
        }

    },[])
    if(props.match.params.rid){ // якщо це посилання з пошти

        if(props.auth.recoverIdConfirmed === null){

            return <div className={c.form_container}>checking</div>

        }else if(props.auth.recoverIdConfirmed === false){
            return <div className={c.form_container}> {t('error.error')}<br/>
                {/*{props.auth.recoverError}*/}
                {props.auth.recoverError ? <div>
                    <div className={(!props.auth.recoverError ? c.noError : '') + ' ' + c.error}>
                        {t('error.'+props.auth.recoverError)}

                    </div>
                </div> : ''}
                </div>
            // todo errors
        }else if(props.auth.recoverIdConfirmed === true){
            return <div className={c.form_container}>

                <h1>{t('recover.title2')}</h1>
                {(props.auth.recoverCompleted === true )
                    ? <div className={c.sc}>{t('recover.password_changed')} <br/><br/></div>
                    : <>
                        <NewPassReduxForm onSubmit={onSubmit} {...props} />

                        {t('auth.if_no_login')} <NavLink to='/signup'>{t('auth.register')}</NavLink><br/>
                        {t('auth.if_you_have_login')} <NavLink to='/login'>{t('auth.login')}</NavLink>
                    </>
                }

            </div>


        }


    }else{  // якщо це ініціація
        return <div className={c.form_container}>
            <h1>{t('recover.title')}</h1>
            {(props.auth.recoverEmailSent === true )
                ? <div className={c.sc}>{t('recover.link_sent')} <br/><br/></div>
                : ''
            }
            <RecoverReduxForm onSubmit={onSubmit} {...props} /><br/>
            {/*<LoginReduxForm2 onSubmit={onSubmit2} {...props} />*/}
            {t('auth.if_no_login')} <NavLink to='/signup'>{t('auth.register')}</NavLink><br/>
            {t('auth.if_you_have_login')} <NavLink to='/login'>{t('auth.login')}</NavLink>
        </div>
    }


    // const onSubmit2 = ({email2,pass2,rememberMe2}) => { //formData
    //     //
    //     // console.log('email = ' + email2)
    //     if (props.auth.isAuth === false) {
    //         props.login(email2, pass2, rememberMe2)
    //     } else {
    //         return <Redirect to={'/index'}/>
    //     }
    //     // console.log(formData)
    // }

}
const maxLen50 = maxLenCreator(50);
const minLen2 = minLenCreator(2);
// const numOnly = onlyNumbers();

// main Recover_form
const RecoverForm = ({auth, handleSubmit, error,setCaptchaAnswer,rememberMe,setRM,recover,ban_reason}) => {

    // remember captcha in State for both accounts (to they both could use it while authorizing)
    // const formChanged = (e) => {
    //     if(e.target.name === 'captcha'){
    //         setCaptchaAnswer(e.target.value)
    //     }
    // }

    const { t } = useTranslation();
    const [loadingButton,setLB] = useState(false)

    const rmClick = (e) => {
        // console.log(e.target.checked);
        // setRM(e.target.checked);
    }

    const onFinish = async (values) => {
        // let rm = values['rememberMe'] === undefined ? false : values['rememberMe'];
        console.log('Success:', values);
        if (auth.isAuth === false || auth.temp === '1') {
            recover(values['email'])
                .then(()=>{
                    setLB(false)
                })
        } else {
            return <Redirect to={'/index'}/>
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        setLB(false)
    };


    const buttonClicked = () => {
        setLB(true)
    }
    const style = { background: '#0092ff', padding: '8px 0' };

    if (auth.isAuth === false || auth.temp === '1') {

        return <Row >
            <Col className="gutter-row"
                 xs={{ span: 22, offset: 1 }}
                 sm={{ span: 20, offset: 2 }}
                 md={{ span: 16, offset: 4 }}
                 lg={{ span: 12, offset: 6 }}
                 xl={{ span: 8, offset: 8 }}
                 xxl={{ span: 6, offset: 9 }}
            >
                {/*<div style={style}>col-6</div>*/}
                <Form onSubmit={handleSubmit}
                    // onChange={formChanged}

                      name="basic"
                      labelCol={{
                          // span: 8,
                      }}
                      // wrapperCol={{
                      //     span: 12,
                      //     offset: 6
                      // }}
                      initialValues={{
                          remember: true,
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                >

                    {error ? <Form.Item>
                        <div className={(!error ? c.noError : '') + ' ' + c.error}>
                            {t('error.'+error)}
                            {error==='you_are_banned' && ban_reason
                                ? '<br>'+ban_reason
                            : ''}
                        </div>
                    </Form.Item> : ''}
                    <Form.Item
                        // label="email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: t('recover.email_mess'),
                            },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder={t('recover.email_place')}
                            // suffix={<Tooltip title="Extra information">
                            //     <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                            // </Tooltip>}


                        />
                        {/*{createMyField('login/email', Input, 'email', [required, maxLen50, minLen2],{
                prefix:<UserOutlined className="site-form-item-icon" />,suffix:
                    <Tooltip title="Extra information">
                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
            })}*/}
                    </Form.Item>


                    {/*<br/>*/}

                    {/*<Form.Item*/}
                    {/*    // label="Password"*/}
                    {/*    name="pass"*/}
                    {/*    rules={[*/}
                    {/*        {*/}
                    {/*            required: true,*/}
                    {/*            message: t('auth.pass_mess'),*/}
                    {/*        },*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    <Input.Password*/}
                    {/*        prefix={<UnlockOutlined/>}*/}
                    {/*        placeholder={t('auth.pass_place')}*/}
                    {/*    />*/}
                    {/*</Form.Item>*/}

                    {/*{createMyField('pass', Input.Password, 'pass', [required, maxLen50, minLen2], )}*/}
                    {/*<br/>*/}


                    {/*<div className={c.captchaDiv + ' ' + (!auth.capthaImg ? c.noCaptcha : '')}>*/}
                    {/*    <div>*/}
                    {/*        <img alt='captcha' src={auth.capthaImg ? auth.capthaImg : ''}/>*/}
                    {/*    </div>*/}
                    {/*    {createMyField('enter captcha', Input, 'captcha', (!auth.capthaImg ? [] : [required, maxLen50, minLen2]))}*/}
                    {/*</div>*/}

                    {/*{createMyField(null, Input, 'rememberMe', [], {type: 'checkbox',checked: rememberMe,onClick:rmClick}, "Remember me")}*/}
                    {/*<div>*/}

                    {/*<Form.Item name="rememberMe" valuePropName="checked" >*/}
                    {/*    <Checkbox>{t('auth.remember_me')}</Checkbox>*/}
                    {/*</Form.Item>*/}

                    {/*<Button  type="primary" onClick={handleSubmit}>Log in</Button>*/}


                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loadingButton} onClick={buttonClicked}>
                            {t('recover.button')}
                        </Button>
                    </Form.Item>


                    {/*<Button  type="primary" loading>Log in</Button>*/}
                    {/*</div>*/}
                </Form>
            </Col>

        </Row>

    } else {
        return <Redirect to={'/index'}/>
    }


}
const NewPassForm = ({auth, handleSubmit, error,setPassword,setRecoverError}) => {

    const { t } = useTranslation();
    const [loadingButton,setLB] = useState(false)
    const [oneTimeSubmit,setOTS] = useState(false)

    // useEffect(()=>{
    //     setInterval(()=>{
    //         console.log('timer')
    //         console.log(loadingButton)
    //         if(loadingButton) setLB(false)
    //     },2000)
    // },[])

    useEffect(()=>{
        console.log('auth.recoverError')
        console.log(auth.recoverError)
        console.log(loadingButton)
        if(auth.recoverError === 'different_pass' )
        setLB(false)
    },[auth.recoverError !== null ? auth.recoverError : auth])



    const onFinish = async (values) => {
        // let rm = values['rememberMe'] === undefined ? false : values['rememberMe'];
        console.log('Success:', values);
        setLB(true)
        setRecoverError(false)
        if ((auth.isAuth === false || auth.temp === '1')/* && !oneTimeSubmit*/) {
            // setOTS(true);
            setPassword(values['email'],values['pass'],values['pass2'])
                // .then(()=>{
                //     //
                // })

        } else {
            return <Redirect to={'/index'}/>
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        setLB(false)
    };


    const buttonClicked = () => {
        setLB(true)
    }
    const style = { background: '#0092ff', padding: '8px 0' };

    if (auth.isAuth === false || auth.temp === '1') {
        return <Row >
            <Col className="gutter-row"
                 xs={{ span: 22, offset: 1 }}
                 sm={{ span: 20, offset: 2 }}
                 md={{ span: 16, offset: 4 }}
                 lg={{ span: 12, offset: 6 }}
                 xl={{ span: 8, offset: 8 }}
                 xxl={{ span: 6, offset: 9 }}
            >
                {/*<div style={style}>col-6</div>*/}
                <Form onSubmit={handleSubmit}
                    // onChange={formChanged}

                      name="basic"
                      labelCol={{
                          // span: 8,
                      }}
                      // wrapperCol={{
                      //     span: 12,
                      //     offset: 6
                      // }}
                      initialValues={{
                          email: auth.email
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                >

                    {auth.recoverError ? <Form.Item>
                        <div className={(!auth.recoverError ? c.noError : '') + ' ' + c.error}>
                            {t('error.'+auth.recoverError)}
                        </div>
                    </Form.Item> : ''}
                    <Form.Item
                        // label="email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: t('auth.email_mess'),
                            },
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder={t('recover.email_place')}
                            // suffix={<Tooltip title="Extra information">
                            //     <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                            // </Tooltip>}
                            readOnly
                        />
                        {/*{createMyField('login/email', Input, 'email', [required, maxLen50, minLen2],{
                prefix:<UserOutlined className="site-form-item-icon" />,suffix:
                    <Tooltip title="Extra information">
                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
            })}*/}
                    </Form.Item>


                    {/*<br/>*/}

                    <Form.Item
                        // label="Password"
                        name="pass"
                        rules={[
                            {
                                required: true,
                                message: t('auth.pass_mess'),
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<UnlockOutlined/>}
                            placeholder={t('auth.pass_place')}
                        />
                    </Form.Item>
                    <Form.Item
                        // label="Password"
                        name="pass2"
                        rules={[
                            {
                                required: true,
                                message: t('auth.pass2_mess'),
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<UnlockOutlined/>}
                            placeholder={t('auth.pass2_place')}
                        />
                    </Form.Item>

                    {/*{createMyField('pass', Input.Password, 'pass', [required, maxLen50, minLen2], )}*/}
                    {/*<br/>*/}


                    {/*<div className={c.captchaDiv + ' ' + (!auth.capthaImg ? c.noCaptcha : '')}>*/}
                    {/*    <div>*/}
                    {/*        <img alt='captcha' src={auth.capthaImg ? auth.capthaImg : ''}/>*/}
                    {/*    </div>*/}
                    {/*    {createMyField('enter captcha', Input, 'captcha', (!auth.capthaImg ? [] : [required, maxLen50, minLen2]))}*/}
                    {/*</div>*/}

                    {/*{createMyField(null, Input, 'rememberMe', [], {type: 'checkbox',checked: rememberMe,onClick:rmClick}, "Remember me")}*/}
                    {/*<div>*/}

                    {/*<Form.Item name="rememberMe" valuePropName="checked" >*/}
                    {/*    <Checkbox>{t('auth.remember_me')}</Checkbox>*/}
                    {/*</Form.Item>*/}

                    {/*<Button  type="primary" onClick={handleSubmit}>Log in</Button>*/}


                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loadingButton} onClick={buttonClicked}>
                            {t('recover.button2')}
                        </Button>
                    </Form.Item>


                    {/*<Button  type="primary" loading>Log in</Button>*/}
                    {/*</div>*/}
                </Form>
            </Col>

        </Row>

    } else {
        return <Redirect to={'/index'}/>
    }


}

// secondary login form for TESTing
// const LoginForm2 = ({auth, handleSubmit, error}) => {
//
//     if (auth.isAuth === false) {
//
//         return <form onSubmit={handleSubmit}>
//
//             <div className={(!error ? c.noError : '') + ' ' + c.error}>
//                 {error}
//             </div>
//             {createMyField(null, Hidden, 'email2', [])}
//             {createMyField(null, Hidden, 'pass2', [])}
//
//             {/*  NO CAPTCHA NEEDED */}
//             {/*<div className={c.captchaDiv + ' ' + (!auth.capthaImg ? c.noCaptcha : '')}>*/}
//             {/*    <div>*/}
//             {/*        <img alt='captcha' src={auth.capthaImg ? auth.capthaImg : ''}/>*/}
//             {/*    </div>*/}
//             {/*    {createMyField('enter captcha', Input, 'captcha2', (!auth.capthaImg ? [] : [required, maxLen50, minLen2]))}*/}
//             {/*</div>*/}
//
//             {createMyField(null, Hidden, 'rememberMe2', [])}
//             <div>
//                 <button>Log in for test</button>
//             </div>
//         </form>
//     } else {
//         return <Redirect to={'/index'}/>
//     }
//
//
// }

const RecoverReduxForm = reduxForm({
    form: 'recover'
})(RecoverForm)
const NewPassReduxForm = reduxForm({
    form: 'newpass'
})(NewPassForm)
// const LoginReduxForm2 = reduxForm({
//     form: 'auth2'
// })(LoginForm2)


const mapStateToProps = ({auth}) => {
    //debugger
    return {
        auth: auth,
        // initialValues: {
        //     email2: 'wovo4ka2010@gmail.com',
        //     pass2: '3AtGqkmDkKfmX6J',
        //     // email2: 'free@samuraijs.com',
        //     // pass2: 'free',
        //     rememberMe2: true,
        //
        // },

        //capthaImg: props.capthaImg
    }
};

export default compose(
    connect(mapStateToProps, {recover, checkAuthorization,setCaptchaAnswer,checkRid,setPassword,setRecoverError}),
)(RecoverContainer)

//
// export default () => {
//     return <>
//         <a href={`https://social-network.samuraijs.com/login`} target={`_blank`}>Потрібна авторизація</a>
//     </>
// }