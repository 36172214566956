import React, {useEffect, useState} from "react";
import c from "./FavouritesModule.module.css";
import AdminLinks from "../AdminLinks/AdminLinks";
import {getCatListSel, getItemsListSel} from "../../redux/admin_selectors";
import {connect} from "react-redux";
import {delItem, getCatList, getItemList, saveItem} from "../../redux/admin_reducer";
import Preloader from "../Preloader/Preloader";
import {NavLink, Redirect, withRouter} from "react-router-dom";
import AdminItemList from "../AdminItemList/AdminItemList";
import {compose} from "redux";
import {CheckBox, createMyField, Hidden, Input, Textarea} from "../common/FormsControls/FormsControls";
import {reduxForm} from "redux-form";
import Info from "../common/Info/Info";
import {required} from "../../utils/validate/validator";
import AdminPhotoView from "../AdminPhotoView/AdminPhotoView";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import {getFavs, getMyOrders, updFavs} from "../../redux/user_reducer";
import {Table, Tag} from "antd";
import {useTranslation} from "react-i18next";
import {Header} from "antd/lib/layout/layout";
import {createMarkup, getColorByStatus} from "../common/functions";
import Icon, {HeartTwoTone} from "@ant-design/icons";



// https://docs.dndkit.com/presets/sortable





const FavouritesModule = (props) => {

    const {t} = useTranslation();
    useEffect(() => {
        if (props.favourites === null) {
            // console.log('test 1')
            props.getFavs()
        } else {
            // console.log('test 2')
            // console.log(props.favourites)
        }

        // console.log(props.id)

    }, [])

    const HeartSvg = () => (
        <svg viewBox="64 64 896 896" focusable="false" data-icon="heart" width="1em" height="1em" fill="currentColor"
             aria-hidden="true">
            <path
                d="M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9zM512 814.8S156 586.7 156 385.5C156 283.6 240.3 201 344.3 201c73.1 0 136.5 40.8 167.7 100.4C543.2 241.8 606.6 201 679.7 201c104 0 188.3 82.6 188.3 184.5 0 201.2-356 429.3-356 429.3z"
                fill="#eb2f96"></path>
            <path
                d="M679.7 201c-73.1 0-136.5 40.8-167.7 100.4C480.8 241.8 417.4 201 344.3 201c-104 0-188.3 82.6-188.3 184.5 0 201.2 356 429.3 356 429.3s356-228.1 356-429.3C868 283.6 783.7 201 679.7 201z"
                fill="#eb2f96"></path>
        </svg>
    );

    const MyHeartIconFilled = props => <Icon component={HeartSvg} {...props} />;

    const addToFavs = (id) => {
        let f
        if (props.favs === null || props.favs === undefined) {
            f = []
        } else {
            f = [...props.favs];
        }

        // console.log(f)
        f.push(id)
        props.updFavs(f.join())
        // props.favs.push(id)

    }
    const removeFromFavs = (id) => {
        let f
        if (props.favs === null || props.favs === undefined) {
            f = []
        } else {
            f = [...props.favs];
        }
        // console.log(f)
        for (let i = 0; i < f.length; i++) {

            if (f[i] === id) {
                f.splice(i, 1);
                i--;
            }
        }
        props.updFavs(f.join())
    }

    return (props.favs === null || props.favs === undefined || props.favs.indexOf(props.id) < 0)
        ? <HeartTwoTone twoToneColor="#eb2f96" className={c.fav} title={t("cart.fav_add_title")}
                        onClick={() => addToFavs(props.id)}/>
        : <MyHeartIconFilled className={c.fav} title={t("cart.fav_del_title")} onClick={() => removeFromFavs(props.id)}/>

}
const mstp = (state,props) => {



    return {
        favs: state.user.favourites_ids
    }
}

export default compose(withRouter,connect(mstp,{getFavs,updFavs}))(FavouritesModule)