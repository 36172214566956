import {API} from "../api/api";
// import {setItemList} from "./admin_reducer";
// import {setCatList} from "./admin_reducer";

let initialState = {
    item: null
}

const SET_ITEM = 'sweetkids/item/SET_ITEM';
// const SET_ITEM_LIST = 'sweetkids/index/SET_ITEM_LIST';

const item_reducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_ITEM:
            // debugger
            return {
                ...state,
                item: action.item,
            }
/*
        case SET_USER_DATA:
            return {
                ...state,
                ...action.payload,
            }
        /!*case LOG_OUT:
            return {
                ...state,
                isAuth: false
            }*!/

        case SET_USER_IMG:
            return {
                ...state,
                userImg: action.img
            }

        case SET_CAPTCHA_IMG:
            return {
                ...state,
                capthaImg: action.img
            }
        case SET_CAPTCHA_ANS:
            return {
                ...state,
                captchaAnswer: action.ans
            }*/
        /*        case SET_ERROR:
                    return {
                        ...state,
                        loginError:action.err
                    }*/


        default:
            return state;

    }

}

export default item_reducer;



export const setItem = (item) => {
    return {type: SET_ITEM, item: item};
}



export const getItem = (id) => async (dispatch) => {
    try {
        const result = await API.getItem(id);
        // console.log('getCatListMain');
        // console.log(result);
        if(result !== undefined){
            dispatch(setItem(result.item));
        }

    }catch (e) {
        // console.log(e);
    }

}

export const itemLoaded = (id) => async (dispatch) => {
    try {
        const result = await API.itemLoaded(id);
        // console.log('getCatListMain');
        // console.log(result);
        // if(result !== undefined){
        //     dispatch(setItem(result.item));
        // }

    }catch (e) {
        // console.log(e);
    }

}

