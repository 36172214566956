import React, {useEffect, useState} from "react";
import c from "./AdminViewOrder.module.css";
import AdminLinks from "../AdminLinks/AdminLinks";
import {getCatListSel, getItemsListSel} from "../../redux/admin_selectors";
import {connect} from "react-redux";
import {
    changeOrderStatus,
    delItem,
    getAdminViewOrder,
    getCatList,
    getItemList,
    saveItem
} from "../../redux/admin_reducer";
import Preloader from "../Preloader/Preloader";
import {NavLink, Redirect, withRouter} from "react-router-dom";
import AdminItemList from "../AdminItemList/AdminItemList";
import {compose} from "redux";
import {CheckBox, createMyField, Hidden, Input, Textarea} from "../common/FormsControls/FormsControls";
import {reduxForm} from "redux-form";
import Info from "../common/Info/Info";
import {required} from "../../utils/validate/validator";
import AdminPhotoView from "../AdminPhotoView/AdminPhotoView";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import {getMyOrders} from "../../redux/user_reducer";
import {Button, Dropdown, Image, Menu, Table, Tag} from "antd";
import {getViewOrder} from "../../redux/order_reducer";
import {createMarkup, getColorByStatus, minutes_seconds_text, parseDate} from "../common/functions";
import {useTranslation} from "react-i18next";



// https://docs.dndkit.com/presets/sortable





const AdminViewOrder = (props) => {

    let [status,setStatus] = useState('initial')
    const { t } = useTranslation();
    let order_id = 0;
    if(props.match.params.id && props.match.params.id !== ''){
        order_id = parseInt(props.match.params.id)
    }

    // let deps = props.viewOrder

    useEffect(()=>{
        if(props.viewOrder !== null){
            console.log(props.viewOrder.status)
            setStatus(props.viewOrder.status)
        }else{
            console.log('no viewOrder')
        }

    },[props.viewOrder])

    useEffect(()=>{
        if(props.viewOrder === null || props.viewOrder.id !== order_id){
            // console.log('test 1')
            props.getAdminViewOrder(order_id)
        }else{
            // console.log('test 2')
            // console.log(props.myOrders)
        }


    },[])


    useEffect(()=>{
        if(props.settings.settings !== null)
        {
            console.log('setL')
            console.log(props.settings.settings.pay_wait_time)
            // setL(props.settings.settings.pay_wait_time)
        }

    },[props.settings.settings !== null ? props.settings.settings.pay_wait_time : null])

    // if(props.myOrders !== null){
    //     console.log(props.myOrders)
    // }



    const columns = [
        {
            // title: 'ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            // title: 'Update Date',
            dataIndex: 'value',
            key: 'value',
        },
    ];



    // return (props.myOrders === null ? <Preloader/> : <>
    //
    //     {props.auth.isAuth && props.auth.login !== 'no_login'
    //         ? <>
    //             <h1>Мої замовлення</h1>
    //             <>
    //                 {/*ID DATE AMOUNT STATUS<br/>*/}
    //
    //                 <Table dataSource={dataSource} columns={columns} />;
    //             </>
    //
    //         </>
    //         : <>Для доступу до усього функціоналу особистого кабінету <NavLink to="/login">потрібно зареєструватись і авторизуватись</NavLink></>
    //     }
    // </>)

    const goToPaying = () => {
        // return <Redirect to={'/pay/'+props.viewOrder.id} />
        props.history.push('/pay/'+props.viewOrder.id)
    }

    // const getTextByStatus = (status) => {
    //     let out = '';
    //     switch (status) {
    //         case 'paying':
    //             out = <span><span className={c.order_paying}>Замовлення в процесі оплати</span> <Button onClick={goToPaying} danger>Перейти до оплати</Button></span>;
    //             break;
    //         case 'check_time':
    //             out = <span dangerouslySetInnerHTML={createMarkup(t('orderView.time_is_up'))} className={c.time_is_up}></span>;
    //             break;
    //         case 'canceled':
    //             out = <span className={c.order_canceled}>Замовлення скасоване</span>;
    //             break;
    //         case 'confirmed_by_user':
    //             out = <span className={c.order_confirmed_by_user}>Замовлення підтверджене замовником та чекає схвалення Адміністратором</span>;
    //             break;
    //         case 'confirmed':
    //             out = <Tag color={'cyan'} key={status}>
    //                 {t('order_status.'+status).toUpperCase()}
    //             </Tag>;
    //             break;
    //         case 'delivering':
    //             out = <Tag color={'cyan'} key={status}>
    //                 {t('order_status.'+status).toUpperCase()}
    //             </Tag>;
    //             break;
    //         case 'completed':
    //             out = <span className={c.order_completed}>Замовлення виконане</span>;
    //             break;
    //
    //         default:
    //
    //             out = <span className={c.order_error}>Помилка отримання статусу замовлення, спробуйте перезавантажити сторінку</span>;
    //             break;
    //     }
    //     return out;
    // }



    function parseDeliveryWay(dw) {
        if(dw === 'up'){
            return t('orderView.delivery.up')
        }
        if(dw === 'np'){
            return t('orderView.delivery.np')
        }
    }

    const parseItemsList = (items) => {
        let dataSource2 = Array();
        // let itemsArr = items.split('||');
        items.split('||').forEach((i)=>{
            let itemArr = i.split('|');
            // return '<a href="/item/'+itemArr[0]+'">'+itemArr[1]+'</a>'
            dataSource2.push({
                name: <NavLink to={'/item/'+itemArr[0]}>{itemArr[1]}</NavLink>,
                ppo: itemArr[2],
                count: itemArr[3],
            })
        })
        // return ia.join('<br/>')

        let columns2 = [
            {
                title: t('orderView.itemTitle'),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: t('orderView.pricePerOne'),
                dataIndex: 'ppo',
                key: 'ppo',
            },
            {
                title: t('orderView.count'),
                dataIndex: 'count',
                key: 'count',
            },
        ];

        // let dataSource2 = [
        //     {
        //         name: 'test1',
        //         ppo: '200',
        //         count: '2',
        //     },
        //     {
        //         name: 'test2',
        //         ppo: '300',
        //         count: '3',
        //     },
        //     {
        //         name: 'test3',
        //         ppo: '100',
        //         count: '1',
        //     },
        // ]


        return  <Table dataSource={dataSource2} columns={columns2} bordered={true}
                       pagination={{ position: ['none', 'none'] }} size='small' />

    }

    const changeStatus = (id,status) => {
        console.log(id,status)
        props.changeOrderStatus(id,status)
        setStatus(status)
    }

    const statuses = Array(
        {v:'paying'},
        {v:'canceled'},
        {v:'delivering'},
        {v:'confirmed'},
        {v:'completed'},
        {v:'check_time'},
        {v:'confirmed_by_user'},
        {v:'error'});

    //     statuses.forEach((s)=>{
    //      console.log(s.v)
    //         submenu += <Menu.Item> + s.v + </Menu.Item>
    // })



    const getDropdownMenu = (id) => {
        return  (
            <Menu>
                {statuses.map((s)=>{
                    return <Menu.Item onClick={()=>changeStatus(id,s.v)}>{s.v}</Menu.Item>
                })}
            </Menu>
        );
    }
    //
    // const getDataSource = () => {

        let dataSource = Array()
        if(props.viewOrder !== null){
            // let dataSource = Array()
            // console.log(props.viewOrder)

            dataSource.push({
                key: t('orderView.payment_amount'),
                value: (props.viewOrder.pay_way === 'part' ? props.settings.settings.part_paying_amount : props.viewOrder.total_amount) + ' ' + t('common.curr_sign')
            })
            dataSource.push({
                key: t('orderView.total_amount'),
                value: props.viewOrder.total_amount + ' ' + t('common.curr_sign')
            })
            dataSource.push({
                key: t('orderView.status'),
                // value: getTextByStatus(props.viewOrder.status)
                value: <Dropdown overlay={getDropdownMenu(props.viewOrder.id)} placement={'topCenter'} >

                        <Tag color={getColorByStatus(status)} key={status}>
                            <div dangerouslySetInnerHTML={createMarkup(t('order_status.'+status).toUpperCase())}></div>
                        </Tag>
                </Dropdown>
            })

            for (let k in props.viewOrder) {
                // console.log(k)
                if (k !== 'id' && k !== 'user_id' && k !== 'pay_way' && k !== 'total_amount' && k !== 'status' ) {
                    dataSource.push({
                        key: t('orderView.'+k),
                        value: (
                            k === 'create_date' || k === 'update_date'
                                ? parseDate(props.viewOrder[k])
                                : (k === 'delivery_way'
                                        ? parseDeliveryWay(props.viewOrder[k])
                                        : (k === 'items'
                                                ? parseItemsList(props.viewOrder[k])
                                                : (k === 'phone'
                                                        ? '+380'+(props.viewOrder[k])
                                                        : props.viewOrder[k]
                                                )
                                        )
                                )
                        ),
                    })
                }

            }
            //     props.viewOrder.map((k)=>{
            // //         dataSource.push( {
            // //             id : o.id,
            // //         update_date : o.update_date,
            // //         amount : o.total_amount,
            // //         status : o.status
            // // })
            //
            //
            //         // return <><NavLink to={'/pay/'+o.id}>{o.id+' '+o.update_date +' '+o.total_amount +' '+o.status}</NavLink><br/></>
            //     })
            // console.log(dataSource)
        }
    //     return dataSource;
    // }


    return (order_id <= 0
            ? <>{t('order_status.error')}</>
            : ((props.viewOrder === null )
                ? <Preloader />
                : <div className={c.viewOrder_container}>
                    <NavLink to={'/adminko/admin_orders'}>&lt;- {t('orderView.back_to_list')}</NavLink>

                    <h1 className={c.viewOrder_header}>{t('orderView.title') + props.viewOrder.id}</h1>

                    <div className={c.viewOrder_data}>
                        {/*{t('pay.order_id')} - <span className={c.order_id}>{order_id}</span><br/>
                        <div>
                            <span className={c.viewOrder_subheader}>{t('pay.payment_amount')}</span> - <span >{props.viewOrder.pay_way === 'part' ? props.settings.settings.part_paying_amount : props.viewOrder.total_amount} {t('pay.valuta')}</span>
                        </div>
                        <div>
                            <span className={c.viewOrder_subheader}>{t('orderView.total_order_amount')}</span> - <span >{props.viewOrder.total_amount} {t('pay.valuta')}</span>
                        </div>
                        <div>
                            <span className={c.viewOrder_subheader}>{t('orderView.status')}</span> - <span >{getTextByStatus(props.viewOrder.status)}</span>
                        </div>
                        <div>
                            <span className={c.viewOrder_subheader}>{t('orderView.date')}</span> - <span >{parseDate(props.viewOrder.create_date)}</span>
                        </div>
                        <div>
                            <span className={c.viewOrder_subheader}>{t('orderView.updDate')}</span> - <span >{parseDate(props.viewOrder.update_date)}</span>
                        </div>
                        <div>
                            <span className={c.viewOrder_subheader}>{t('orderView.deliveryWay')}</span> - <span >{parseDeliveryWay(props.viewOrder.delivery_way)}</span>
                        </div>
                        <div>
                            <span className={c.viewOrder_subheader}>{t('orderView.address')}</span> - <span >{(props.viewOrder.address)}</span>
                        </div>
                        <div>
                            <span className={c.viewOrder_subheader}>{t('orderView.receiver')}</span> - <span >{(props.viewOrder.full_name)}</span>
                        </div>
                        <div>
                            <span className={c.viewOrder_subheader}>{t('orderView.phone')}</span> - <span >{(props.viewOrder.phone)}</span>
                        </div>
                        <div>
                            <span className={c.viewOrder_subheader}>{t('orderView.items')}</span> - <span >{(props.viewOrder.items)}</span>
                        </div>
                        */}


                        <Table dataSource={dataSource} columns={columns} showHeader={false} size='small' pagination={{ position: ['none', 'none'] }} />


                    </div>
                    {/*<div className={c.payment_details_qr}>
                        <Image src='https://sweetkids.site/imgs/card_qr.jpg' width={200}/>
                    </div>
                    <div dangerouslySetInnerHTML={createMarkup(t('pay.main_text'))} className={c.payment_details_text}></div>
                    <br/>

                    <Button onClick={cancel_order}
                            disabled={time_is_up || props.order.order.status !== 'paying'
                                ? 'disabled'
                                : null}
                            className={time_is_up || props.order.order.status !== 'paying' ? c.disabled_btn : c.cancel_order}>Cancel order</Button>
                    <Button onClick={confirm_payment}
                            disabled={time_is_up || props.order.order.status !== 'paying'
                                ? 'disabled'
                                : null}
                            className={time_is_up || props.order.order.status !== 'paying' ? c.disabled_btn : c.confirm_payment}>Confirm payment</Button>

                    {t('auth.if_no_login')} <NavLink to='/signup'>{t('auth.register')}</NavLink>*/}
                </div>)
    )
}

const mstp = (state,props) => {



    return {
        auth: state.auth,
        myOrders: state.user.myOrders,
        viewOrder: state.admin.viewOrder,
        settings: state.settings
    }
}

export default compose(withRouter,connect(mstp,{getItemList,saveItem,getCatList,getMyOrders,getAdminViewOrder,changeOrderStatus}))(AdminViewOrder);