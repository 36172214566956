import {API} from "../api/api";
// import {stopSubmit} from "redux-form";
import {setInitialized, setNoInet} from "./app_reducer";
// import {Redirect} from "react-router-dom";
import {getCart, newCart} from "./cart_reducer";

const SET_MY_ORDERS = 'sweet/user/SET_MY_ORDERS';
const SET_FAVOURITES = 'sweet/user/SET_FAVOURITES';
const SET_FAVOURITES_IDS = 'sweet/user/SET_FAVOURITES_IDS';
// const SET_CAPTCHA_ANS = 'sweet/auth/SET_CAPTCHA_ANS';
// const SET_SIGNUP_COMPLETED = 'sweet/auth/SET_SIGNUP_COMPLETED';

let initialState = {
    myOrders: null,
    favourites: null,
    favourites_ids: null
}


const user_reducer = (state = initialState, action:any) => {

    switch (action.type) {

        case SET_MY_ORDERS:
            return {
                ...state,
                myOrders: action.myOrders,
            }
        case SET_FAVOURITES:
            return {
                ...state,
                favourites: action.favs,
            }
        case SET_FAVOURITES_IDS:
            return {
                ...state,
                favourites_ids: action.favs_IDs,
            }

        default:
            return state;

    }

}

export default user_reducer;


export const setMyOrders = (myOrders: any) => {
    return {type: SET_MY_ORDERS, myOrders:myOrders};
}
export const setFavs = (favs: any) => {
    return {type: SET_FAVOURITES, favs:favs};
}
export const setFavsIDs = (favs_IDs: any) => {
    return {type: SET_FAVOURITES_IDS, favs_IDs:favs_IDs};
}
// export const setSignupCompleted = () => {
//     return {type: SET_SIGNUP_COMPLETED};
// }

/////  THUNKS


export const getMyOrders = () => async (dispatch:any) => {
    try{
        let data = await API.getMyOrders();
        if (data.error === 0) {
            if(data.main_data.message === null || data.main_data.message === undefined ){
                // console.log('data.main_data.orders')
                // console.log(data.main_data.orders)
                dispatch(setMyOrders(data.main_data.orders));
                // dispatch(setUserAuthData(id, email, login, true, null, null, null,al,address,phone,temp));
                // if(data.main_data.message === 'new_user') dispatch(newCart())
                // dispatch(setInitialized())
            }else{
                if(data.main_data.message === 'no orders'){
                    dispatch(setMyOrders([]));
                }
                // todo - опрацювати усі меседжі

                // console.log('data.main_data.message')
                // console.log(data.main_data.message)
            }

            // let resp2 = await API.getProfile(id);
            // dispatch(setUserProfile(resp2.data));
            // let data2 = await API.getStatus(id);
            // dispatch(setUserStatus(data2));

            // let promise1 = await API.getProfile(id);
            // let promise2 = await API.getStatus(id);
            // let data3 = await Promise.all([promise1,promise2])
            //     .then( () => {
            //
            //         dispatch(setUserProfile(promise1.data));
            //         dispatch(setUserStatus(promise2));
            //         // dispatch(setInitialized())
            //     })


        }else{

            // console.log('data.error')
            // console.log(data.error)
        }
    }
    catch(error){
        // console.log(error)
        dispatch(setNoInet(true))
        console.log('NoInet')
    }

}

export const getFavs = () => async (dispatch:any) => {
    try{
        let data = await API.getFavs();
        if (data.error === 0) {
            if(data.main_data.message === null || data.main_data.message === undefined || data.main_data.message === 'ok'){
                dispatch(setFavs(data.main_data.favs));
                dispatch(setFavsIDs(data.main_data.favs_ids));
            }else{
                // todo - опрацювати усі меседжі
                console.log('data.main_data.message')
                console.log(data.main_data.message)
            }
        }else{

        }
    }
    catch(error){
        // console.log(error)
        dispatch(setNoInet(true))
        console.log('NoInet')
    }

}

export const updFavs = (newFavsIDs:any) => async (dispatch:any) => {
    try{
        let data = await API.setFavs(newFavsIDs);
        if (data.error === 0) {
            if(data.main_data.message === null || data.main_data.message === undefined || data.main_data.message === 'ok' ){
                dispatch(setFavs(data.main_data.favs));
                dispatch(setFavsIDs(data.main_data.favs_ids));
            }else{
                // todo - опрацювати усі меседжі
                console.log('data.main_data.message')
                console.log(data.main_data.message)
            }
        }else{
            console.log(data.error)
        }
    }
    catch(error){
        console.log(error)
        dispatch(setNoInet(true))
        console.log('NoInet')
    }

}


// export const logOut = () => async (dispatch) => {
//     await API.logOut()
//     // console.warn('logout2');
//     //dispatch(setUserLogOut());
//     dispatch(setUserAuthData(null, null, null, false, null, null, null,null,null,null));
//     dispatch(getCart())
// }
// export const setCaptchaAnswer = (c) => async (dispatch) => {
//     // console.log('dsd')
//     // console.log(c)
//     dispatch(setUserAuthCaptchaAnswer(c));
// }
//
//
// export const login = (email, pass, remember) => async (dispatch,getState) => {
//     let captcha = getState().auth.captchaAnswer;
//     let data = await API.login(email, pass, remember, captcha);
//      // console.log('data')
//      // console.log(data)
//     if (data.error === 0) {
//         // console.log('login +')
//         dispatch(checkAuthorization());
//         dispatch(getCart())
//     } else {
//         //dispatch(setUserAuthError(data.messages.join('<br/>')));
//
//         dispatch(stopSubmit('auth', {_error: data.main_data.message/*.join('<br/>')*/}));
//
//         // if (data.resultCode === 10) {
//         //     let data = await API.getCaptcha();
//         //     // dispatch(setUserAuthCaptchaImg(data.url));
//         //     // todo зробити виведення помилок - а треба?
//         // }
//     }
//
//
// }
//
// export const signup = (login,email, pass, phone, addr) => async (dispatch,getState) => {
//     // let captcha = getState().auth.captchaAnswer;
//     let data = await API.signup(login,email, pass,phone,addr);
//      // console.log('data')
//      // console.log(data)
//     if (data.error === 0) {
//         // console.log('signup +')
//         dispatch(setSignupCompleted());
//
//     } else {
//         //dispatch(setUserAuthError(data.messages.join('<br/>')));
//
//         dispatch(stopSubmit('signup', {_error: data.main_data.message/*.join('<br/>')*/}));
//
//         // if (data.resultCode === 10) {
//         //     let data = await API.getCaptcha();
//         //     // dispatch(setUserAuthCaptchaImg(data.url));
//         //     // todo зробити виведення помилок - а треба?
//         // }
//     }
//
//
// }