export const getTotal = (itemsString,cartItemsFull) => {
    // console.log(itemsString,cartItemsFull)
    let parsedFull = parseCartItemsFull(cartItemsFull)
    // console.log(parsedFull)
    let total = 0;
    let itemsArrTemp = []
    if(itemsString !== undefined && itemsString !== ''){
        itemsArrTemp = itemsString.split('||');
    }

    // console.log(itemsArrTemp.length)
    if(itemsArrTemp.length > 0){
        itemsArrTemp.map((i)=>{
            if(i !== 'undefined' && i !== ''){
                let item = i.split('|');
                let diff = parsedFull[parseInt(item[0])];
                // console.log(item)
                // console.log(parsedFull[parseInt(item[0])])
                if(diff >= parseInt(item[3]))
                    total = parseInt(total) + parseInt(item[2]) * parseInt(item[3])
                if(isNaN(total)) total = 0;
            }

        })
    }else{
        total = 0
    }

    return total
}

const parseCartItemsFull = (cartItemsFull) => {
    let out = []
    if(cartItemsFull !== undefined && cartItemsFull !== null){
        cartItemsFull.map((i)=>{
            return out[i.id] = i.count - i.booked
        })
    }
    return out
}

export const cleanUndefinedItems = (itemsString) => {
    if(itemsString !== undefined){
        let itemsArr = itemsString.split('||');
        return itemsArr.filter((i)=>{
            if(i !== 'undefined' && i !== '')
                return i
        }).join('||')
    }else{
        return null
    }


}
export const cleanUndefinedItemsIDs = (itemsIDsString) => {

    if(itemsIDsString !== undefined){
        let itemsArr = itemsIDsString.split(',');
        return itemsArr.filter((i)=>{
            if(i !== 'undefined' && i !== '')
                return i
        }).join(',')
    }else{
        return null
    }


}

export const checkIfItemIsInCart = (id,array) => {

    let test = array.indexOf(''+id);
    // console.log(test)
    return test > -1

}

export const parseCartItemsIds = (itemsString) => {
    if(itemsString !== undefined){
        if (itemsString !== '' && itemsString !== null && itemsString !== undefined){
            let itemsArrTemp = itemsString.split('||');
            return itemsArrTemp.map((i)=>{
                return i.split('|')[0]
            })
        }else{
            return []
        }
    }else{
        return []
    }
}

export const getDiff = (start,length) => {
    let curDate = Date.now()/1000
    let diff = (start + length) - curDate;
    // console.log(curDate,diff)
    let minutes = parseInt(diff / 60);
    let seconds = parseInt(diff - minutes * 60);
    return [minutes,seconds];
}

export const clearAllIntervals = () => {
    // Get a reference to the last interval + 1
    const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
        window.clearInterval(i);
    }
}



export const minutes_seconds_text = (minutesORseconds) => {
    minutesORseconds = minutesORseconds + ''

    let last = parseInt(minutesORseconds[minutesORseconds.length - 1])
    if(last === 0){
        return 0;
    }
    if(last === 1){
        return 1;
    }
    if(last > 1 && last < 5 ){
        return 2;
    }
    if(last > 4){
        return 5;
    }
}

export const createMarkup = (text) => {
    return {__html: text};
}


export const getColorByStatus = (status) => {
    let out = '';


    switch (status) {
        case 'check_time':
            out = 'blue';
            break;
        case 'paying':
            out = 'green';
            break;
        case 'canceled':
            out = 'red';
            break;
        case 'cancel':
            out = 'red';
            break;
        case 'delivering':
            out = 'magenta';
            break;
        case 'completed':
            out = 'default';
            break;
        case 'confirmed_by_user':
            out = 'purple';
            break;
        case 'confirmed':
            out = 'cyan';
            break;

        default:

            out = 'default';
            break;
    }
    return out;
}

export function parseDate(number) {
    let d = new Date(+number * 1000)
    function pad(s) { return (s < 10) ? '0' + s : s; }
    // var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/') + ' '
        + [pad(d.getHours()), pad(d.getMinutes()), pad(d.getSeconds())].join(':')
}