import {API} from "../api/api";
import {setNoInet} from "./app_reducer";
// import {setItemList} from "./admin_reducer";
// import {setCatList} from "./admin_reducer";

// type InitialStateType = {
//     catList: null | [],
//     itemList: null | [],
//     countItems: number,
//     itemsTotal: number,
//     itemsOrder: string,
//     sortBy: null | [],
//     itemsPerPage: number
// }

// type CatListType = any
// type ItemListType = any
// type SortByListType = any

let initialState = {
    searchLineOpened: false as boolean,
    // searchResult
}

export type InitialStateType = typeof initialState

const SET_SEARCH_LINE_OPENED = 'sweetkids/search/SET_SEARCH_LINE_OPENED';
// const SET_ITEM_LIST = 'sweetkids/index/SET_ITEM_LIST';
// const SET_COUNT_ITEMS = 'sweetkids/index/SET_COUNT_ITEMS';
// const APPEND_TO_ITEMS = 'sweetkids/index/APPEND_TO_ITEMS';
// const SET_ITEMS_TOTAL = 'sweetkids/index/SET_ITEMS_TOTAL';

const search_reducer = (state = initialState, action: any): InitialStateType => {

    switch (action.type) {

        case SET_SEARCH_LINE_OPENED:
            // debugger
            return {
                ...state,
                searchLineOpened: action.searchLineOpened,
            }
        // case SET_ITEM_LIST:
        //     // debugger
        //     return {
        //         ...state,
        //         itemList: action.items,
        //     }
        // case APPEND_TO_ITEMS:
        //     // debugger
        //     return {
        //         ...state,
        //         itemList: [...state.itemList, ...action.items] ,
        //     }
        // case SET_COUNT_ITEMS:
        //     // debugger
        //     return {
        //         ...state,
        //         countItems: action.countItems,
        //     }
        // case SET_ITEMS_TOTAL:
        //     // debugger
        //     return {
        //         ...state,
        //         itemsTotal: action.itemsTotal,
        //     }

        default:
            return state;

    }

}

export default search_reducer;



export const setSearchLineOpened = (searchLineOpened: boolean) => {
    return {type: SET_SEARCH_LINE_OPENED, searchLineOpened: searchLineOpened};
}
// export const setItemListMain = (items:ItemListType) => {
//     return {type: SET_ITEM_LIST, items: items};
// }
// export const setCountItems = (countItems:number) => {
//     return {type: SET_COUNT_ITEMS, countItems: countItems};
// }
// export const appendToItemListMain = (items:ItemListType) => {
//     return {type: APPEND_TO_ITEMS, items: items};
// }
// export const setItemsTotal = (itemsTotal:number) => {
//     return {type: SET_ITEMS_TOTAL, itemsTotal: itemsTotal};
// }


// export const getCatListMain = () => async (dispatch: any) => {
//     try{
//         const result = await API.getCats();
//         if(result !== undefined){
//             dispatch(setCatListMain(result.cats));
//         }
//     }catch(error){
//         console.log(error)
//         // dispatch(setNoInet(true))
//     }
// }
//
//
// export const getItemListMain = (itemsPerPage:number,currentPage:number,cats:any,sorting:any) => async (dispatch:any,getState:any) => {
//     try{
//         // let state = getState();
//         // let ci = state.index.countItems;
//         const result = await API.getFilteredItems(itemsPerPage,0,cats,sorting);
//         if(result !== undefined){
//             // console.log('getItemListMain');
//             // console.log(result);
//             dispatch(setItemListMain(result.items));
//             dispatch(setCountItems(result.items.length));
//             dispatch(setItemsTotal(result.total));
//         }else{
//             // dispatch(setNoInet(true))
//         }
//
//
//     }
//     catch(error){
//         console.log(error)
//         // dispatch(setNoInet(true))
//     }
// }
//
// export const getMoreItems = (cats:any,sorting:any) => async (dispatch:any,getState:any) => {
//     // try{
//         let state = getState();
//         // console.log(state);
//         let ipp = state.index.itemsPerPage
//         let io = state.index.itemsOrder
//         let ci = state.index.countItems
//         const result = await API.getMoreFilteredItems(ipp,ci,cats,sorting);
//         // console.log(result);
//         if(result !== undefined){
//             // console.log('getItemListMain');
//             console.log(result);
//             dispatch(appendToItemListMain(result.items));
//             dispatch(setCountItems(ci + result.items.length));
//             dispatch(setItemsTotal(result.total));
//
//         }else{
//             // dispatch(setNoInet(true))
//         }
//
//
//     // }
//     // catch(error){
//     //     console.log(error)
//     //     // dispatch(setNoInet(true))
//     // }
// }

// export const getItems = () => async (dispatch)  => {
//     const result = await API.getFilteredItems()
//     console.log('getItems');
//     console.log(result);
// }