

export const lightTheme = {



}

export const darkTheme = {

    body: '#353535',

text: '#FAFAFA',

toggleBorder: '#6B8096',

background: '#999',

}
