import React, {useEffect, useState} from "react";
import c from "./Cabinet.module.css";
import AdminLinks from "../AdminLinks/AdminLinks";
import {getCatListSel, getItemsListSel} from "../../redux/admin_selectors";
import {connect} from "react-redux";
import {delItem, getCatList, getItemList, saveItem} from "../../redux/admin_reducer";
import Preloader from "../Preloader/Preloader";
import {NavLink, Redirect, withRouter} from "react-router-dom";
import AdminItemList from "../AdminItemList/AdminItemList";
import {compose} from "redux";
import {CheckBox, createMyField, Hidden, Input, Textarea} from "../common/FormsControls/FormsControls";
import {reduxForm} from "redux-form";
import Info from "../common/Info/Info";
import {required} from "../../utils/validate/validator";
import AdminPhotoView from "../AdminPhotoView/AdminPhotoView";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import {useTranslation} from "react-i18next";
import {createMarkup} from "../common/functions";



// https://docs.dndkit.com/presets/sortable





const Cabinet =  (props) => {


    const { t } = useTranslation();
    return <div className={c.main_body}>

        {props.auth.isAuth && props.auth.login !== 'no_login'
            ? <>
                <h1>{t('cabinet.title')}</h1>
                <NavLink to='/my_orders'>{t('cabinet.my_orders')}</NavLink> <br/>
                <NavLink to='/my_info'>{t('cabinet.my_info')}</NavLink><br/>
                <NavLink to='/favourites'>{t('cabinet.favourites')}</NavLink>
            </>
            : <div dangerouslySetInnerHTML={createMarkup(t('cabinet.not_authorized'))}></div>
        }
    </div>
}

const mstp = (state,props) => {

    return {
        auth: state.auth,
        // edititem: edititem,
        // cats: getCatListSel(state),
/*
        id',formData.id)
        out.append('name',formData.itemName)
        out.append('descr',formData.itemDescr)
        out.append('active',formData.active ? '1' : '0')
        out.append('count',formData.count)
        out.append('price',formData.price)
        out.append('prev_photo',tempArrItems.join() )
        out.append('cats',catsChecked.join() )
        out.append('discount_money',formData.discount_money)
        out.append('discount_percent',formData.discount_percent)
        out.append('old_price',formData.old_price)
        out.append('old_price_active',formData.old_price_active ? '1' : '0')
        out.append('item_order
            */

        // initialValues: {
        //     id: edititem.id !== undefined ? edititem.id : '',
        //     itemName: edititem.name !== undefined ? edititem.name : '',
        //     itemDescr: edititem.descr !== undefined ? edititem.descr : '',
        //     count: edititem.count !== undefined ? edititem.count : '',
        //     price: edititem.price !== undefined ? edititem.price : '',
        //     discount_money: edititem.discount_money !== undefined ? edititem.discount_money : '',
        //     discount_percent: edititem.discount_percent !== undefined ? edititem.discount_percent : '',
        //     old_price: edititem.old_price !== undefined ? edititem.old_price : '',
        //     // old_price_active: edititem.old_price_active !== undefined ? edititem.old_price_active : '',
        //     item_order: edititem.item_order !== undefined ? edititem.item_order : '',
        // }
    }
}

export default compose(withRouter,connect(mstp,{getItemList,saveItem,getCatList}))(Cabinet);